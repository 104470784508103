import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface MortalityProps {
  description: string;
  rate: number;
  quantity: number;
}

const AddMortalityService = (payload: MortalityProps) => {
  return HTTP_CLIENT.post(Endpoint.mortality.add, payload);
};

const GetAllMortalityService = (
  search: string,
  startDate: string,
  endDate: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.mortality.get +
      `${
        search
          ? `?search=${search}&startDate=${startDate}&endDate=${endDate}`
          : `?startDate=${startDate}&endDate=${endDate}`
      }`
  );
};

const GetMortalityStatsService = ({ startDate, endDate }: FilterType) => {
  return HTTP_CLIENT.get(
    Endpoint.mortality.stats + `?startDate=${startDate}&endDate=${endDate}`
  );
};

const EditMortalityService = (payload: MortalityProps, id: number) => {
  return HTTP_CLIENT.put(
    Endpoint.mortality.edit.replace(":id", String(id)),
    payload
  );
};

const DeleteMortalityService = (id: number) => {
  return HTTP_CLIENT.delete(
    Endpoint.mortality.delete.replace(":id", String(id))
  );
};

export {
  AddMortalityService,
  GetAllMortalityService,
  GetMortalityStatsService,
  EditMortalityService,
  DeleteMortalityService,
};
