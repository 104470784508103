import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface ProductionProps {
  description: string;
  size: string;
  patee: number;
  tray: number;
}

const AddProductionService = (payload: ProductionProps) => {
  return HTTP_CLIENT.post(Endpoint.production.add, payload);
};

interface GetAllProductionsServiceProps {
  startDate?: string;
  endDate?: string;
  search: string;
}

const GetAllProductionsService = ({
  search,
  startDate,
  endDate,
}: GetAllProductionsServiceProps) => {
  return HTTP_CLIENT.get(
    Endpoint.production.get +
      `${
        search
          ? `?search=${search}&startDate=${startDate}&endDate=${endDate}`
          : `?startDate=${startDate}&endDate=${endDate}`
      }`
  );
};

const GetProductionsStatsService = ({ startDate, endDate }: FilterType) => {
  return HTTP_CLIENT.get(
    Endpoint.production.stats + `?startDate=${startDate}&endDate=${endDate}`
  );
};

const EditProductionService = (payload: ProductionProps, id: number) => {
  return HTTP_CLIENT.put(
    Endpoint.production.edit.replace(":id", String(id)),
    payload
  );
};

const DeleteProductionService = (id: number) => {
  return HTTP_CLIENT.delete(
    Endpoint.production.delete.replace(":id", String(id))
  );
};

export {
  AddProductionService,
  GetAllProductionsService,
  EditProductionService,
  DeleteProductionService,
  GetProductionsStatsService,
};
