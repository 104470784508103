import classNames from "classnames";
import { useState } from "react";
import styles from "./style.module.scss";
import { SettingsTabs, SettingsTabsEnums } from "shared/utils/constants";
import CustomTab from "shared/components/common/customTabs";
import EditProfile from "shared/components/settingsComponents/editProfile";
import ChangePassword from "shared/components/settingsComponents/changePassword";

const Settings = () => {
  const [activeTab, setActiveTab] = useState<string>(SettingsTabsEnums.account);
  return (
    <div className={classNames(styles.listContainerStyle)}>
      <div
        className={classNames(styles.mainContainer, styles.containerPadding)}
      >
        <h2 className={classNames(styles.title)}>Settings</h2>
        <CustomTab
          tabs={SettingsTabs}
          activeTab={activeTab}
          handleActiveTab={(tab) => {
            setActiveTab(tab);
          }}
        />
        {activeTab === SettingsTabsEnums.account ? (
          <EditProfile />
        ) : (
          <ChangePassword />
        )}
      </div>
    </div>
  );
};

export default Settings;
