import classNames from "classnames";
import { useFormik } from "formik";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomModal from "shared/components/common/customModal";
import CustomTextArea from "shared/components/common/customTextArea";
import ModalHeader from "shared/components/common/modalHeader";
import { AddMoralityVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import {
  AddCullingService,
  EditCullingService,
} from "shared/services/cullingService";
import { toastMessage } from "shared/components/common/toast";
import moment from "moment";

interface InitialValuesProps {
  description: string;
  rate: number;
  quantity: number;
  date: string;
}

interface AddCullingModalProps extends ModalProps {
  cullingItem?: any;
  cullingsList?: any[];
  isEdit?: boolean;
  setCullingList: (value: any[]) => void;
}

const AddCullingModal = ({
  cullingItem,
  cullingsList,
  isEdit,
  show,
  handleClose,
  setCullingList,
}: AddCullingModalProps) => {
  const initialValues: InitialValuesProps = {
    date: cullingItem?.date
      ? moment(cullingItem?.date).format("YYYY-MM-DD")
      : "",
    description: cullingItem?.description || "",
    rate: cullingItem?.rate || 0,
    quantity: cullingItem?.quantity || 0,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddMoralityVS,
    onSubmit: (value, action) => {
      handleCulling();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleCulling = () => {
    setSubmitting(true);
    if (isEdit) {
      EditCullingService(values, cullingItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Culling updated successfully");
          if (cullingItem) {
            const updatedList: any = cullingsList?.map((item) => {
              if (item._id === cullingItem._id) {
                return data;
              }
              return item;
            });
            setCullingList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddCullingService(values)
        .then(({ data }) => {
          toastMessage("success", "Culling added successfully");
          if (cullingsList) {
            let tempList = [...cullingsList];
            tempList.unshift(data);
            setCullingList?.(tempList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Culling"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <CustomInput
              label="Date"
              placeholder="1/11/2021"
              required
              isDate
              type="date"
              value={values.date}
              error={touched.date && errors.date ? errors.date : ""}
              onChange={handleChange("date")}
            />
            <CustomInput
              label="Rate"
              placeholder="rate"
              required
              isNumber
              value={values.rate}
              error={touched.rate && errors.rate ? errors.rate : ""}
              formikKey="rate"
              setFieldValue={setFieldValue}
            />
            <CustomInput
              label="Quantity"
              placeholder="quantity"
              required
              isNumber
              value={values.quantity}
              error={touched.quantity && errors.quantity ? errors.quantity : ""}
              formikKey="quantity"
              setFieldValue={setFieldValue}
            />
            <CustomTextArea
              label="Description"
              placeholder="Write something"
              required
              customInputContainer={classNames(styles.customInputContainer)}
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddCullingModal;
