import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardWraper from "shared/components/common/dashboardWrapper";
import { routeConstant } from "./routeConstant";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component }: Partial<LayoutProps>) {
  const {
    auth: { isLoggedIn },
  } = useSelector((state: any) => state.root);
  const location = useLocation();

  useEffect(() => {
    document.title = title + " | Mughal Poultry Services";
    // eslint-disable-next-line
  });

  return !isLoggedIn ? (
    <>
      <Component />
    </>
  ) : location.pathname === routeConstant.ledger.path ? (
    <Component />
  ) : (
    <DashboardWraper>
      <Component />
    </DashboardWraper>
  );
}

export default Layout;
