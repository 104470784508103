import Dashboard from "./dash_home.png";
import DashSettings from "./dash_settings.png";
import DashCalender from "./dash_calender.png";
import DashTags from "./dash_tags.png";
import DashSales from "./dash_users.png";
import DashStock from "./dash_stock.png";
import DashExpenses from "./dash_wallet.png";
import DashMorality from "./dash_morality.png";
import DashFlock from "./dash_flock.png";
import DashCulling from "./dash_culling.png";
import DashMisc from "./dash_briefcase.png";
import DashFeed from "./dash_leaf.png";
import DashElectricity from "./dash_bulb.png";
import DashVaccine from "./dash_vaccine.png";
import DashMedicine from "./dash_medicine.png";
import DashFuel from "./dash_fuel.png";
import DashSalary from "./dash_salary.png";
import DashEggSales from "./dash-egg-sales.png";
import DashTransferSales from "./dash-transfer-sales.png";
import DashOtherSales from "./dash-other-sales.png";
import DashLedger from "./dash_ledger.png";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as Logout } from "./log-out.svg";
import { ReactComponent as ChevLeft } from "./chev-left.svg";
import { ReactComponent as ChevRight } from "./chev-right.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as EyeDisable } from "./eye-disable.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as DropDown } from "./dropDown.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as ChevDown } from "./chev-down.svg";
import { ReactComponent as TrashOutline } from "./trash-outline.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as TickSquare } from "./tick-square.svg";
import { ReactComponent as DoubleLeftChev } from "./double-left-chev.svg";
import { ReactComponent as DoubleRightChev } from "./double-right-chev.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as CameraPlus } from "./camera-plus.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Calender } from "./calendar.svg";

const Icons = {
  Rocket,
  DashSettings,
  Logout,
  Dashboard,
  ChevLeft,
  ChevRight,
  Mail,
  Lock,
  Eye,
  EyeDisable,
  Hamburger,
  DropDown,
  Phone,
  Cross,
  Trash,
  Search,
  ChevDown,
  TrashOutline,
  User,
  TickSquare,
  DoubleLeftChev,
  DoubleRightChev,
  ArrowLeft,
  ArrowRight,
  CameraPlus,
  DashCalender,
  DashTags,
  DashSales,
  DashStock,
  DashExpenses,
  DashMorality,
  DashFlock,
  DashCulling,
  DashMisc,
  DashFeed,
  DashElectricity,
  DashVaccine,
  DashMedicine,
  DashFuel,
  DashSalary,
  DashEggSales,
  DashTransferSales,
  DashOtherSales,
  DashLedger,
  Pencil,
  Calender,
};

export { Icons };
