import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface GetDashboardStatsServiceProps {
  startDate?: string;
  endDate?: string;
}

const GetDashboardStatsService = ({
  startDate,
  endDate,
}: GetDashboardStatsServiceProps) => {
  return HTTP_CLIENT.get(
    Endpoint.dashboard.stats + `?startDate=${startDate}&&endDate=${endDate}`
  );
};

const GetLedgerService = ({
  startDate,
  endDate,
}: GetDashboardStatsServiceProps) => {
  return HTTP_CLIENT.get(
    Endpoint.dashboard.ledger + `?startDate=${startDate}&&endDate=${endDate}`
  );
};

export { GetDashboardStatsService, GetLedgerService };
