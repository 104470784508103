import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./reducers/authSlice";
import breadCrumbSlice from "./reducers/breadCrumbSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  breadcrumb: breadCrumbSlice,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "breadcrumb"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: {
    root: persistedReducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["register"],
        ignoredActionPaths: ["rehydrate", "register"],
        ignoredPaths: ["register"],
      },
    }),
});

const persistor = persistStore(store);
export { persistor, store };
