import React from "react";

const ForgotPassword = () => {
  return (
    <div>
      <div>ForgotPassword</div>
    </div>
  );
};

export default ForgotPassword;
