import { Icons } from "assets";
import classNames from "classnames";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomPhoneInput from "shared/components/common/customPhoneInput";
import { toastMessage } from "shared/components/common/toast";
import { isImage } from "shared/utils/helper";
import { UpdateProfileVS } from "shared/utils/validation";
import Avatar from "./avatar";
import styles from "./style.module.scss";
import { EditUserService } from "shared/services/userService";
import { setAuthReducer } from "shared/redux/reducers/authSlice";

const EditProfile = () => {
  const {
    auth: { user },
  } = useSelector((state: any) => state.root);
  const dispatch = useDispatch();

  const [profileURL, setProfileURL] = useState<string>("");

  const initialValues: UpdateProfileType = {
    firstName: user?.firstname ? user?.firstname : "",
    lastName: user?.lastname ? user?.lastname : "",
    email: user?.email ? user?.email : "",
    phoneNumber: user?.phone ? user?.phone : "",
    address: user?.address ? user?.address : "",
    profilePic: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UpdateProfileVS,
    onSubmit: (value, action) => {
      action.setSubmitting(true);
      handleUpdate();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = formik;

  const handleFileInput = (e: any) => {
    let file = e.target.files[0];
    if (isImage(file.type)) {
      let url = URL.createObjectURL(file);
      setProfileURL(url);
      setFieldValue("profilePic", file);
    } else {
      toastMessage("error", "Only JPG, JPEG, PNG are supported");
    }
  };

  const handleUpdate = () => {
    setSubmitting(true);
    const formData = new FormData();
    if (values.profilePic) {
      formData.append("profile_picture", values.profilePic);
    }
    formData.append("firstname", values.firstName);
    formData.append("lastname", values.lastName);
    formData.append("phone", values.phoneNumber);
    EditUserService(formData)
      .then(({ data }) => {
        dispatch(
          setAuthReducer({
            user: { ...data },
          })
        );
        toastMessage("success", "Profile Updated Successfully");
      })
      .catch((err) => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classNames("flex flex-col items-start gap-6 w-full")}>
      <h3 className={classNames(styles.title)}>Account Information</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classNames("flex flex-col gap-2 w-full")}
      >
        <div
          className={classNames(
            "flex flex-col items-center gap-2 mb-3",
            styles.profileContainer
          )}
        >
          <span className={classNames(styles.avtTitle, "self-start")}>
            Upload Profile Photo
          </span>
          <div className={classNames(styles.avtContainer)}>
            <Avatar profileURL={profileURL} />
            <label
              className={classNames(styles.cameraContainer)}
              htmlFor="profile-input"
            >
              <Icons.CameraPlus />
              <input
                type="file"
                className="hidden"
                id="profile-input"
                onChange={handleFileInput}
              />
            </label>
          </div>
          {touched.profilePic && errors.profilePic && (
            <div className={classNames(styles.error)}>
              {String(errors.profilePic)}
            </div>
          )}
        </div>
        <div
          className={classNames(
            "flex flex-col sm:flex-row items-center w-full gap-2 sm:gap-5"
          )}
        >
          <CustomInput
            label="First Name"
            placeholder="Enter your first name"
            required
            type="text"
            value={values.firstName}
            error={
              touched.firstName && errors.firstName ? errors.firstName : ""
            }
            onChange={handleChange("firstName")}
            customInputContainer={classNames("w-1/2")}
          />
          <CustomInput
            label="Last Name"
            placeholder="Enter your last name"
            required
            type="text"
            value={values.lastName}
            error={touched.lastName && errors.lastName ? errors.lastName : ""}
            onChange={handleChange("lastName")}
          />
        </div>
        <div
          className={classNames(
            "flex flex-col sm:flex-row items-center w-full gap-2 sm:gap-5"
          )}
        >
          <CustomInput
            label="Email"
            placeholder="Enter your email"
            required
            Icon={Icons.Mail}
            type="email"
            value={values.email}
            error={touched.email && errors.email ? errors.email : ""}
            onChange={handleChange("email")}
            readOnly
          />
          <CustomPhoneInput
            label="Phone Number"
            required
            error={
              touched.phoneNumber && errors.phoneNumber
                ? errors.phoneNumber
                : ""
            }
            value={values.phoneNumber}
            onChange={(val: any) => {
              setFieldValue("phoneNumber", val);
            }}
          />
        </div>

        <CustomButton
          title="Save Changes"
          containerStyle={classNames("self-end")}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default EditProfile;
