import React from "react";

const OTP = () => {
  return (
    <div>
      <div>OTP</div>
    </div>
  );
};

export default OTP;
