import { Icons } from "assets";
import classNames from "classnames";

import { useState } from "react";
import styles from "./style.module.scss";
import OptionsDropDown from "../options";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  error?: string;
  notRequiredMargin?: boolean;
  customLabelStyle?: any;
  customInputContainer?: any;
  customIconStyle?: any;
  readOnlyColor?: string;
  options: OptionProps[];
}

const CustomSelect = ({
  label,
  required,
  error,
  value,
  placeholder,
  notRequiredMargin,
  customLabelStyle,
  customInputContainer,
  customIconStyle,
  readOnly,
  defaultValue,
  readOnlyColor,
  options,
}: InputProps) => {
  const [openProfileDropDown, setOpenDropDown] = useState<boolean>(false);

  return (
    <div
      className={classNames(
        "relative w-full",
        notRequiredMargin ? "" : error ? "mb-0" : "mb-3"
      )}
    >
      <div className="flex flex-col items-start w-full gap-2">
        {label ? (
          <label
            className={classNames(
              styles.inputLabel,
              customLabelStyle && customLabelStyle
            )}
          >
            {label} {!!required && <label className={styles.asterik}>*</label>}
          </label>
        ) : null}

        <div
          className={classNames(
            styles.inputContainer,
            readOnly ? styles.readonly : "",
            customInputContainer && customInputContainer,
            "w-full gap-2 cursor-pointer "
          )}
          style={readOnlyColor ? { background: readOnlyColor } : {}}
          onClick={() => {
            setOpenDropDown(true);
          }}
        >
          <span
            className={classNames(value ? styles.value : styles.placeholder)}
          >
            {value ? value : placeholder}
          </span>
          <Icons.ChevDown
            className={classNames(customIconStyle, styles.iconStyle)}
          />
        </div>
        <OptionsDropDown
          options={options}
          openSelection={openProfileDropDown}
          setOpenSelection={setOpenDropDown}
          customContainer={styles.optionsContainer}
        />
      </div>
      {!!error && <div className={classNames(styles.error)}>{error}</div>}
    </div>
  );
};

export default CustomSelect;
