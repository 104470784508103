import { Icons } from "assets";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomButton from "shared/components/common/customButton";
import CustomTable from "shared/components/common/customTable";
import CustomToolTip from "shared/components/common/customToolTip";
import StatsCard from "shared/components/statsCard";
import useDebounce from "shared/hooks/useDebounce";
import SekeletonLoader from "shared/loaders/skeleton";
import AddTransferSalesModal from "shared/modals/addTransferSales";
import ConfirmationModal from "shared/modals/confirmation";
import {
  DeleteSalesService,
  GetAllSalesService,
  GetSalesStatsService,
} from "shared/services/salesService";
import { SALES_TYPES } from "shared/utils/enum";
import styles from "../style.module.scss";
import CustomInput from "shared/components/common/customInput";

const TransferSales = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [statsLoading, setStatsLoading] = useState<boolean>(true);
  const [sales, setSales] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [stat, setStat] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchVal, setSearchVal] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleShowAddModal = () => {
    setSelectedItem(null);
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const showEditModal = (item: any) => {
    setSelectedItem(item);
    setShowAddModal(true);
  };

  const handleShowConfirmationModal = (item: any) => {
    setSelectedItem(item);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteSales = () => {
    setDeleteLoading(true);
    DeleteSalesService(selectedItem)
      .then(() => {
        let newSales = sales.filter((item) => item._id !== selectedItem);
        setSales(newSales);
        handleCloseConfirmationModal();
      })
      .catch((err) => {})
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleGetSalesStats = () => {
    setStatsLoading(true);
    GetSalesStatsService(SALES_TYPES.transfer, startDate, endDate)
      .then(({ data }) => {
        setStat(data);
      })
      .catch((err) => {})
      .finally(() => {
        setStatsLoading(false);
      });
  };

  const handleGetSales = () => {
    setLoading(true);
    GetAllSalesService(searchVal, SALES_TYPES.transfer, startDate, endDate)
      .then(({ data }) => {
        setSales(data);
      })
      .catch((error) => {
        console.log("Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetSales();
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    handleGetSalesStats();
    // eslint-disable-next-line
  }, []);

  useDebounce(
    () => {
      setSearchVal(search);
    },
    [search],
    800
  );

  return (
    <div className={classNames("flex flex-col gap-10")}>
      <div
        className={classNames(
          styles.dateWrapper,
          "gap-3 flex-col xs:flex-row items-start xs:items-center"
        )}
      >
        <div className={classNames("flex items-center justify-start gap-3")}>
          <CustomInput
            type="date"
            placeholder="Start Date"
            notRequiredMargin
            isDate
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setStartDate(e.currentTarget.value);
            }}
          />
          <CustomInput
            type="date"
            placeholder="End Date"
            notRequiredMargin
            isDate
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setEndDate(e.currentTarget.value);
            }}
          />
        </div>
        <CustomButton
          title="Search"
          onClick={() => {
            handleGetSalesStats();
            handleGetSales();
          }}
        />
      </div>
      <div className={classNames("flex items-center flex-wrap gap-10")}>
        <StatsCard
          title="Monthly"
          stats={[
            {
              title: "Amount",
              value: `Rs. ${stat?.monthly ? stat?.monthly : "0"}`,
            },
          ]}
          borderColor="#3498db"
          loading={statsLoading}
        />
        <StatsCard
          title="Yearly"
          stats={[
            { title: "Amount", value: `Rs. ${stat?.year ? stat?.year : "0"}` },
          ]}
          borderColor="#0b8457"
          loading={statsLoading}
        />
        <StatsCard
          title="Total"
          stats={[
            {
              title: "Amount",
              value: `Rs. ${stat?.total ? stat?.total : "0"}`,
            },
          ]}
          borderColor="#eac100"
          loading={statsLoading}
        />
      </div>
      <div className={classNames(styles.tableMain, "mb-4")}>
        <div
          className={classNames(
            "flex items-start gap-3 lg:items-center justify-between flex-col lg:flex-row px-4 py-4"
          )}
        >
          <div className={classNames("flex flex-col items-start")}>
            <h1 className={classNames(styles.bookMainHeading)}>
              Transfer Sales
            </h1>
            <p className={classNames(styles.bookMainTitle)}>
              Showing Transfer sales list here.
            </p>
          </div>
          <div className={classNames("flex flex-wrap items-center gap-3")}>
            <div className={classNames(styles.searchContainer, "flex")}>
              <Icons.Search className={classNames(styles.searchIconStyle)} />
              <input
                className={classNames(styles.searchInputStyle, "ps-2")}
                value={search}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <CustomButton
              title="Add New"
              containerStyle={classNames(styles.buttonMain)}
              onClick={handleShowAddModal}
            />
          </div>
        </div>
        <CustomTable
          title="Transfer Sales"
          heads={[
            "Sr#",
            "Date",
            "Description",
            "Flock Quantity",
            "Feed Quantity",
            "Total Sales Amount",
            "Action",
          ]}
          loading={loading}
          isEmpty={sales.length === 0}
        >
          {loading ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => {
                return <TableRowLoader key={index} />;
              })}
            </>
          ) : (
            <>
              {sales.map((sale, index) => {
                return (
                  <TableRow
                    item={sale}
                    key={index}
                    index={index}
                    showEditModal={showEditModal}
                    handleShowConfirmationModal={handleShowConfirmationModal}
                  />
                );
              })}
            </>
          )}
        </CustomTable>
      </div>
      <AddTransferSalesModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        setSalesList={setSales}
        saleItem={selectedItem}
        salesList={sales}
        isEdit={selectedItem ? true : false}
      />

      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleSubmit={handleDeleteSales}
        loading={deleteLoading}
      />
    </div>
  );
};

const TableRow = ({
  item,
  index,
  showEditModal,
  handleShowConfirmationModal,
}: any) => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ width: "5%" }}
        >
          {index + 1}
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          {moment(item.date).format("DD-MM-YYYY")}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "30%" }}
        >
          {item?.description}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          {item?.flock_quantity}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          {item?.feed_quantity}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          Rs. {item?.amount}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "ps-2 pe-4 py-3")}
          style={{ verticalAlign: "middle", width: "20%" }}
        >
          <div className={classNames("flex items-center gap-2")}>
            <CustomToolTip label="Edit">
              <div
                className={classNames(styles.actionContainer)}
                onClick={() => {
                  showEditModal(item);
                }}
              >
                <Icons.Pencil />
              </div>
            </CustomToolTip>
            <CustomToolTip label="Delete">
              <div
                className={classNames(styles.actionContainer)}
                onClick={() => {
                  handleShowConfirmationModal(item?._id);
                }}
              >
                <Icons.TrashOutline />
              </div>
            </CustomToolTip>
          </div>
        </td>
      </tr>
    </>
  );
};

const TableRowLoader = () => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ width: "5%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "30%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "ps-2 pe-4 py-3")}
          style={{ verticalAlign: "middle", width: "20%" }}
        >
          <div className={classNames("flex items-center gap-2")}>
            <SekeletonLoader className={classNames(styles.actionContainer)} />
            <SekeletonLoader className={classNames(styles.actionContainer)} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TransferSales;
