import { Icons } from "assets";
import classNames from "classnames";
import styles from "./style.module.scss";

interface HeaderProps {
  title: string;
  isBack?: boolean;
  handleClose: () => void;
  handleBack?: () => void;
}

const ModalHeader = ({
  title,
  isBack = false,
  handleClose,
  handleBack,
}: HeaderProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between w-full",
        styles.header
      )}
    >
      <div onClick={handleBack}>{isBack ? <Icons.ArrowLeft /> : null}</div>
      <label>{title}</label>
      <div className={classNames(styles.crossContainer)} onClick={handleClose}>
        <Icons.Cross />
      </div>
    </div>
  );
};

export default ModalHeader;
