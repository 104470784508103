import { Icons, Images } from "assets";

import classNames from "classnames";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OptionsDropDown from "../options";
import styles from "./style.module.scss";
import { resetAuthReducer } from "shared/redux/reducers/authSlice";
import { routeConstant } from "shared/routes/routeConstant";

const ProfileDropDown = () => {
  const {
    auth: { isLoggedIn, user },
  } = useSelector((state: any) => state.root);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openProfileDropDown, setOpenDropDown] = useState<boolean>(false);

  const options: OptionProps[] = [
    {
      title: "My Profile",
      Icon: null,
      action: () => {
        navigate(routeConstant.settings.path);
      },
    },
    {
      title: "Log out",
      Icon: Icons.Logout,
      action: () => {
        dispatch(resetAuthReducer());
      },
    },
  ];

  return (
    <>
      {isLoggedIn ? (
        <div className="relative">
          <div
            className={classNames("flex items-center gap-3 cursor-pointer ")}
            onClick={() => {
              setOpenDropDown(true);
            }}
          >
            <img
              src={
                user?.profile_picture_full_path
                  ? user?.profile_picture_full_path
                  : Images.DefaultAvatar
              }
              alt=""
              className={classNames(styles.profileAvt)}
            />
            <div className={classNames("flex items-start ")}>
              <div className={classNames("hidden flex-col gap-1 sm:flex")}>
                <span className={classNames(styles.profileName)}>
                  {user?.firstname + " " + user?.lastname}
                </span>
                <span className={classNames(styles.profileEmail)}>
                  {user?.email}
                </span>
              </div>
              <Icons.DropDown className={classNames(styles.dropDownIcon)} />
            </div>
          </div>
          {openProfileDropDown ? (
            <div className={classNames(styles.extra)} />
          ) : null}
          <OptionsDropDown
            options={options}
            openSelection={openProfileDropDown}
            setOpenSelection={setOpenDropDown}
            customContainer={styles.optionsContainer}
          />
        </div>
      ) : (
        <div className={"flex items-center gap-3"}>
          <Link
            to={routeConstant.login.path}
            className={classNames(styles.loginBtn)}
          >
            Login
          </Link>
        </div>
      )}
    </>
  );
};

export default ProfileDropDown;
