import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface SalesProps {
  description: string;
  waste?: number;
  patee?: number;
  perPatee?: number;
  tray?: number;
  perTray?: number;
  brokenTray?: number;
  perBrokenTray?: number;
  flock_quantity?: number;
  feed_quantity?: number;
  amount?: number;
  type: number;
}

const AddSalesService = (payload: SalesProps) => {
  return HTTP_CLIENT.post(Endpoint.sales.add, payload);
};

const GetAllSalesService = (
  search: string,
  type: number,
  startDate: string,
  endDate: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.sales.get.replace(":type", String(type)) +
      `${
        search
          ? `?search=${search}&startDate=${startDate}&endDate=${endDate}`
          : `?startDate=${startDate}&endDate=${endDate}`
      }`
  );
};

const GetSalesStatsService = (
  type: number,
  startDate: string,
  endDate: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.sales.stats.replace(":type", String(type)) +
      `?startDate=${startDate}&endDate=${endDate}`
  );
};

const EditSalesService = (payload: SalesProps, id: number) => {
  return HTTP_CLIENT.put(
    Endpoint.sales.edit.replace(":id", String(id)),
    payload
  );
};

const DeleteSalesService = (id: number) => {
  return HTTP_CLIENT.delete(Endpoint.sales.delete.replace(":id", String(id)));
};

export {
  AddSalesService,
  GetAllSalesService,
  GetSalesStatsService,
  EditSalesService,
  DeleteSalesService,
};
