import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

const EditUserService = (payload: any) => {
  return HTTP_CLIENT.put(Endpoint.user.edit, payload);
};

const ChangePasswordService = (payload: any) => {
  return HTTP_CLIENT.put(Endpoint.user.changePassword, payload);
};

export { EditUserService, ChangePasswordService };
