import classNames from "classnames";
import { useEffect, useState } from "react";
import StatsCard from "shared/components/statsCard";
import { GetDashboardStatsService } from "shared/services/dashboardService";
import styles from "./style.module.scss";
import CustomInput from "shared/components/common/customInput";
import CustomButton from "shared/components/common/customButton";

const Dashboard = () => {
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleGetStats = () => {
    GetDashboardStatsService({ startDate, endDate })
      .then(({ data }) => {
        setStats(data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetStats();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classNames("flex flex-col gap-10")}>
      <div
        className={classNames(
          styles.dateWrapper,
          "gap-3 flex-col xs:flex-row items-start xs:items-center"
        )}
      >
        <div className={classNames("flex items-center justify-start gap-3")}>
          <CustomInput
            type="date"
            placeholder="Start Date"
            notRequiredMargin
            isDate
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setStartDate(e.currentTarget.value);
            }}
          />
          <CustomInput
            type="date"
            placeholder="End Date"
            notRequiredMargin
            isDate
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setEndDate(e.currentTarget.value);
            }}
          />
        </div>
        <CustomButton
          title="Search"
          onClick={() => {
            setLoading(true);
            handleGetStats();
          }}
        />
      </div>
      <div className={classNames("flex items-center flex-wrap gap-10")}>
        <StatsCard
          title="Income"
          stats={[
            {
              title: "Amount",
              value: `Rs. ${
                stats?.income ? stats?.income.toLocaleString() : "0"
              }`,
            },
          ]}
          borderColor="#0b8457"
          loading={loading}
        />
        <StatsCard
          title="Expenses"
          stats={[
            {
              title: "Amount",
              value: `Rs. ${
                stats?.expenses ? stats?.expenses.toLocaleString() : "0"
              }`,
            },
          ]}
          borderColor="#eac100"
          loading={loading}
        />
        <StatsCard
          title="Profit"
          stats={[
            {
              title: "Amount",
              value: `Rs. ${
                stats?.profit ? stats?.profit.toLocaleString() : "0"
              }`,
            },
          ]}
          borderColor="#e84a5f"
          loading={loading}
        />
      </div>
      <div className={classNames("flex items-center flex-wrap gap-10")}>
        {stats?.sales ? (
          <StatsCard
            title="Sale"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.sales ? stats?.sales.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#f96d00"
            titleBg="linear-gradient(to right, #f96d00, white)"
            loading={loading}
          />
        ) : null}

        {stats?.production ? (
          <StatsCard
            title="Production"
            stats={[
              {
                title: "Patee",
                value: `${
                  stats?.production ? Math.floor(stats?.production / 12) : "0"
                }`,
              },
              {
                title: "Tray",
                value: `${stats?.production ? stats?.production % 12 : "0"}`,
              },
            ]}
            borderColor="purple"
            titleBg="linear-gradient(to right, purple, white)"
            loading={loading}
          />
        ) : null}

        {stats?.feed ? (
          <StatsCard
            title="Feed"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.feed ? stats?.feed.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#549b8c"
            titleBg="linear-gradient(to right, #549b8c, white)"
            loading={loading}
          />
        ) : null}

        {stats?.misc ? (
          <StatsCard
            title="Misc. Expense"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.misc ? stats?.misc.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#eac100"
            titleBg="linear-gradient(to right, #eac100, white)"
            loading={loading}
          />
        ) : null}

        {stats?.bill ? (
          <StatsCard
            title="Elect. & Gas"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.bill ? stats?.bill.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#e84a5f"
            titleBg="linear-gradient(to right, #e84a5f, white)"
            loading={loading}
          />
        ) : null}

        {stats?.salary ? (
          <StatsCard
            title="Salary"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.salary ? stats?.salary.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#0b8457"
            titleBg="linear-gradient(to right, #0b8457, white)"
            loading={loading}
          />
        ) : null}

        {stats?.vaccine ? (
          <StatsCard
            title="Vaccine"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.vaccine ? stats?.vaccine.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="purple"
            titleBg="linear-gradient(to right, purple, white)"
            loading={loading}
          />
        ) : null}

        {stats?.medicine ? (
          <StatsCard
            title="Medicine"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.medicine ? stats?.medicine.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#f96d00"
            titleBg="linear-gradient(to right, #f96d00, white)"
            loading={loading}
          />
        ) : null}

        {stats?.fuel ? (
          <StatsCard
            title="Fuel"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.fuel ? stats?.fuel.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#ff0000"
            titleBg="linear-gradient(to right, #ff0000, white)"
            loading={loading}
          />
        ) : null}

        {stats?.mortality ? (
          <StatsCard
            title="Mortality"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.mortality ? stats?.mortality.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#e84a5f"
            titleBg="linear-gradient(to right, #e84a5f, white)"
            loading={loading}
          />
        ) : null}

        {stats?.flock ? (
          <StatsCard
            title="Flock"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.flock ? stats?.flock.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#eac100"
            titleBg="linear-gradient(to right, #eac100, white)"
            loading={loading}
          />
        ) : null}

        {stats?.culling ? (
          <StatsCard
            title="Culling"
            stats={[
              {
                title: "Amount",
                value: `Rs. ${
                  stats?.culling ? stats?.culling.toLocaleString() : "0"
                }`,
              },
            ]}
            borderColor="#purple"
            titleBg="linear-gradient(to right, purple, white)"
            loading={loading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Dashboard;
