import { Images } from "assets";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";
import NavItem from "./navItem";
import styles from "./style.module.scss";

interface Props {
  sidebarArr: SideBarItemsType[];
}

function DashboardSidebar(props: Props) {
  const { sidebarArr } = props;
  return (
    <>
      <div className="text-center w-full flex flex-col items-center mt-6">
        <div
          className={classNames(
            "flex items-center w-full justify-center",
            styles.logoContainer
          )}
        >
          <Link to={routeConstant.dashboard.path}>
            <img src={Images.Logo} alt="" />
          </Link>
        </div>
        <div className={classNames(styles.sideNavFull, "flex flex-col gap-3")}>
          {sidebarArr?.map((item, inx) => {
            return (
              <div className="flex flex-col items-start gap-2" key={inx}>
                <label className={classNames(styles.navTitle)}>
                  {item?.title}
                </label>

                <div className="w-full">
                  {item?.paths?.map((s: SideBarItemPathType, s_inx: number) => {
                    const props = {
                      ...s,
                    };
                    return <NavItem {...props} key={s_inx} />;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
