import classNames from "classnames";
import { useFormik } from "formik";
import moment from "moment";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomModal from "shared/components/common/customModal";
import CustomTextArea from "shared/components/common/customTextArea";
import ModalHeader from "shared/components/common/modalHeader";
import { toastMessage } from "shared/components/common/toast";
import {
  AddSalesService,
  EditSalesService,
} from "shared/services/salesService";
import { SALES_TYPES } from "shared/utils/enum";
import { AddOtherSalesVS } from "shared/utils/validation";
import styles from "./style.module.scss";

interface InitialValuesProps {
  description: string;
  amount: number;
  date: string;
  type: number;
}

interface AddOtherSalesModalProps extends ModalProps {
  saleItem?: any;
  salesList?: any[];
  setSalesList?: (list: any[]) => void;
  isEdit?: boolean;
}

const AddOtherSalesModal = ({
  saleItem,
  salesList,
  isEdit,
  show,
  handleClose,
  setSalesList,
}: AddOtherSalesModalProps) => {
  const initialValues: InitialValuesProps = {
    date: saleItem?.date ? moment(saleItem?.date).format("YYYY-MM-DD") : "",
    description: saleItem?.description || "",
    amount: saleItem?.amount || 0,
    type: SALES_TYPES.other,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddOtherSalesVS,
    onSubmit: (value, action) => {
      handleSale();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleSale = () => {
    setSubmitting(true);
    if (isEdit) {
      EditSalesService(values, saleItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Sales updated successfully");
          if (saleItem) {
            const updatedList: any = salesList?.map((item) => {
              if (item._id === saleItem._id) {
                return data;
              }
              return item;
            });
            setSalesList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddSalesService(values)
        .then(({ data }) => {
          toastMessage("success", "Sales added successfully");
          if (salesList) {
            let tempList = [...salesList];
            tempList.unshift(data);
            setSalesList?.(tempList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Sales"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <CustomInput
              label="Date"
              placeholder="1/11/2021"
              required
              isDate
              type="date"
              value={values.date}
              error={touched.date && errors.date ? errors.date : ""}
              onChange={handleChange("date")}
            />
            <CustomInput
              label="Amount"
              placeholder="0"
              required
              isNumber
              value={values.amount}
              error={touched.amount && errors.amount ? errors.amount : ""}
              formikKey="amount"
              setFieldValue={setFieldValue}
            />
            <CustomTextArea
              label="Description"
              placeholder="Write something"
              required
              customInputContainer={classNames(styles.customInputContainer)}
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddOtherSalesModal;
