import React from "react";

const ResetPassword = () => {
  return (
    <div>
      <div>ResetPassword</div>
    </div>
  );
};

export default ResetPassword;
