import { Icons } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import CustomButton from "shared/components/common/customButton";
import CustomTable from "shared/components/common/customTable";
import CustomToolTip from "shared/components/common/customToolTip";
import StatsCard from "shared/components/statsCard";
import AddVaccineExpenseModal from "shared/modals/addVaccine";
import styles from "../style.module.scss";
import useDebounce from "shared/hooks/useDebounce";
import {
  DeleteExpenseService,
  GetExpenseStatsService,
  GetAllExpenseService,
} from "shared/services/expenseService";
import { EXPENSES_TYPES } from "shared/utils/enum";
import moment from "moment";
import SekeletonLoader from "shared/loaders/skeleton";
import ConfirmationModal from "shared/modals/confirmation";
import CustomInput from "shared/components/common/customInput";

const Vaccine = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [statsLoading, setStatsLoading] = useState<boolean>(true);
  const [expenses, setExpenses] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [stat, setStat] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchVal, setSearchVal] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleShowAddModal = () => {
    setSelectedItem(null);
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const showEditModal = (item: any) => {
    setSelectedItem(item);
    setShowAddModal(true);
  };

  const handleShowConfirmationModal = (item: any) => {
    setSelectedItem(item);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteExpense = () => {
    setDeleteLoading(true);
    DeleteExpenseService(selectedItem)
      .then(() => {
        let newMortalities = expenses.filter(
          (item) => item._id !== selectedItem
        );
        setExpenses(newMortalities);
        handleCloseConfirmationModal();
      })
      .catch((err) => {})
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleGetExpenseStats = () => {
    GetExpenseStatsService(EXPENSES_TYPES.vaccine, startDate, endDate)
      .then(({ data }) => {
        setStat(data);
      })
      .catch((err) => {})
      .finally(() => {
        setStatsLoading(false);
      });
  };

  const handleGetExpense = () => {
    setLoading(true);
    GetAllExpenseService(searchVal, EXPENSES_TYPES.vaccine, startDate, endDate)
      .then(({ data }) => {
        setExpenses(data);
      })
      .catch((error) => {
        console.log("Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetExpense();
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    handleGetExpenseStats();
    // eslint-disable-next-line
  }, []);

  useDebounce(
    () => {
      setSearchVal(search);
    },
    [search],
    800
  );

  return (
    <div className={classNames("flex flex-col gap-10")}>
      <div
        className={classNames(
          styles.dateWrapper,
          "gap-3 flex-col xs:flex-row items-start xs:items-center w-full"
        )}
      >
        <div className={classNames("flex items-center justify-start gap-3")}>
          <CustomInput
            type="date"
            placeholder="Start Date"
            notRequiredMargin
            isDate
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setStartDate(e.currentTarget.value);
            }}
          />
          <CustomInput
            type="date"
            placeholder="End Date"
            notRequiredMargin
            isDate
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            customInputContainer={classNames(styles.inputContainer)}
            onChange={(e) => {
              setEndDate(e.currentTarget.value);
            }}
          />
        </div>
        <CustomButton
          title="Search"
          onClick={() => {
            handleGetExpense();
            handleGetExpenseStats();
          }}
        />
      </div>
      <div className={classNames("flex items-center flex-wrap gap-10")}>
        <StatsCard
          title="Monthly"
          stats={[
            {
              title: "Quantity",
              value: stat?.monthly_quantity ? stat?.monthly_quantity : "0",
            },
            {
              title: "Amount",
              value: `Rs. ${stat?.monthly ? stat?.monthly : "0"}`,
            },
          ]}
          borderColor="#3498db"
          loading={statsLoading}
        />

        <StatsCard
          title="Total"
          stats={[
            {
              title: "Quantity",
              value: stat?.total_quantity ? stat?.total_quantity : "0",
            },
            {
              title: "Amount",
              value: `Rs. ${stat?.total ? stat?.total : "0"}`,
            },
          ]}
          borderColor="#eac100"
          loading={statsLoading}
        />
      </div>
      <div className={classNames(styles.tableMain, "mb-4")}>
        <div
          className={classNames(
            "flex items-start gap-3 lg:items-center justify-between flex-col lg:flex-row px-4 py-4"
          )}
        >
          <div className={classNames("flex flex-col items-start")}>
            <h1 className={classNames(styles.bookMainHeading)}>Vaccine</h1>
            <p className={classNames(styles.bookMainTitle)}>
              Showing vaccine expenses here.
            </p>
          </div>
          <div className={classNames("flex flex-wrap items-center gap-3")}>
            <div className={classNames(styles.searchContainer, "flex")}>
              <Icons.Search className={classNames(styles.searchIconStyle)} />
              <input
                className={classNames(styles.searchInputStyle, "ps-2")}
                value={search}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <CustomButton
              title="Add New"
              containerStyle={classNames(styles.buttonMain)}
              onClick={handleShowAddModal}
            />
          </div>
        </div>
        <CustomTable
          title="Vaccine"
          heads={[
            "Sr#",
            "Date",
            "Description",
            "Rate",
            "Qty",
            "Amount",
            "Action",
          ]}
          loading={loading}
          isEmpty={expenses.length === 0}
        >
          {loading ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => {
                return <TableRowLoader key={index} />;
              })}
            </>
          ) : (
            <>
              {expenses.map((expense, index) => {
                return (
                  <TableRow
                    item={expense}
                    key={index}
                    index={index}
                    showEditModal={showEditModal}
                    handleShowConfirmationModal={handleShowConfirmationModal}
                  />
                );
              })}
            </>
          )}
        </CustomTable>
      </div>
      <AddVaccineExpenseModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        setExpensesList={setExpenses}
        expenseItem={selectedItem}
        expensesList={expenses}
        isEdit={selectedItem ? true : false}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleSubmit={handleDeleteExpense}
        loading={deleteLoading}
      />
    </div>
  );
};

const TableRow = ({
  item,
  index,
  showEditModal,
  handleShowConfirmationModal,
}: any) => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ verticalAlign: "middle", width: "8%" }}
        >
          {index + 1}
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          {moment(item.date).format("DD-MM-YYYY")}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "22%" }}
        >
          {item?.description}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          {item?.rate}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          {item?.quantity}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          Rs. {item?.amount}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "ps-2 pe-4 py-3")}
          style={{ verticalAlign: "middle", width: "15%" }}
        >
          <div className={classNames("flex items-center gap-2")}>
            <CustomToolTip label="Edit">
              <div
                className={classNames(styles.actionContainer)}
                onClick={() => {
                  showEditModal(item);
                }}
              >
                <Icons.Pencil />
              </div>
            </CustomToolTip>
            <CustomToolTip label="Delete">
              <div
                className={classNames(styles.actionContainer)}
                onClick={() => {
                  handleShowConfirmationModal(item?._id);
                }}
              >
                <Icons.TrashOutline />
              </div>
            </CustomToolTip>
          </div>
        </td>
      </tr>
    </>
  );
};

const TableRowLoader = () => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ verticalAlign: "middle", width: "8%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "22%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "ps-2 pe-4 py-3")}
          style={{ verticalAlign: "middle", width: "15%" }}
        >
          <div className={classNames("flex items-center gap-2")}>
            <SekeletonLoader className={classNames(styles.actionContainer)} />
            <SekeletonLoader className={classNames(styles.actionContainer)} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default Vaccine;
