import classNames from "classnames";
import { useFormik } from "formik";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomModal from "shared/components/common/customModal";
import CustomTextArea from "shared/components/common/customTextArea";
import ModalHeader from "shared/components/common/modalHeader";
import { AddMoralityVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import {
  AddMortalityService,
  EditMortalityService,
} from "shared/services/mortalityService";
import { toastMessage } from "shared/components/common/toast";
import moment from "moment";

interface InitialValuesProps {
  description: string;
  rate: number;
  quantity: number;
  date: string;
}

interface AddMoralityModalProps extends ModalProps {
  mortalityItem?: any;
  mortalitiesList?: any[];
  setMortalitiesList?: (value: any[]) => void;
  isEdit?: boolean;
}

const AddMoralityModal = ({
  isEdit,
  mortalityItem,
  mortalitiesList,
  show,
  handleClose,
  setMortalitiesList,
}: AddMoralityModalProps) => {
  const initialValues: InitialValuesProps = {
    date: mortalityItem?.date
      ? moment(mortalityItem?.date).format("YYYY-MM-DD")
      : "",
    description: mortalityItem?.description || "",
    rate: mortalityItem?.rate || 0,
    quantity: mortalityItem?.quantity || 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddMoralityVS,
    onSubmit: (value, action) => {
      handleMortality();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleMortality = () => {
    setSubmitting(true);
    if (isEdit) {
      EditMortalityService(values, mortalityItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Mortality updated successfully");
          if (mortalityItem) {
            const updatedList: any = mortalitiesList?.map((item) => {
              if (item._id === mortalityItem._id) {
                return data;
              }
              return item;
            });
            setMortalitiesList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddMortalityService(values)
        .then(({ data }) => {
          toastMessage("success", "Mortality added successfully");
          if (mortalitiesList) {
            let tempList = [...mortalitiesList];
            tempList.unshift(data);
            setMortalitiesList?.(tempList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Morality"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <CustomInput
              label="Date"
              placeholder="1/11/2021"
              required
              isDate
              type="date"
              value={values.date}
              error={touched.date && errors.date ? errors.date : ""}
              onChange={handleChange("date")}
            />
            <CustomInput
              label="Rate"
              placeholder="rate"
              required
              isNumber
              value={values.rate}
              error={touched.rate && errors.rate ? errors.rate : ""}
              formikKey="rate"
              setFieldValue={setFieldValue}
            />
            <CustomInput
              label="Quantity"
              placeholder="quantity"
              required
              isNumber
              value={values.quantity}
              error={touched.quantity && errors.quantity ? errors.quantity : ""}
              formikKey="quantity"
              setFieldValue={setFieldValue}
            />
            <CustomTextArea
              label="Description"
              placeholder="Write something"
              required
              customInputContainer={classNames(styles.customInputContainer)}
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddMoralityModal;
