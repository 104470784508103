import Toast from "shared/components/common/toast";
import AuthRoute from "shared/routes/authRoutes";

function App() {
  return (
    <div className="App">
      <AuthRoute />
      <Toast />
    </div>
  );
}

export default App;
