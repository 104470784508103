import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface ExpenseProps {
  description: string;
  rate?: number;
  quantity?: number;
  type: number;
  date?: any;
  workDays?: number;
  bill_type?: number;
  amount?: number;
}

const AddExpenseService = (payload: ExpenseProps) => {
  return HTTP_CLIENT.post(Endpoint.expense.add, payload);
};

const GetAllExpenseService = (
  search: string,
  type: number,
  startDate?: string,
  endDate?: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.expense.get.replace(":type", String(type)) +
      `${
        search
          ? `?search=${search}&startDate=${startDate}&endDate=${endDate}`
          : `?startDate=${startDate}&endDate=${endDate}`
      }`
  );
};

const GetExpenseStatsService = (
  type: number,
  startDate?: string,
  endDate?: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.expense.stats.replace(":type", String(type)) +
      `?startDate=${startDate}&endDate=${endDate}`
  );
};

const EditExpenseService = (payload: ExpenseProps, id: number) => {
  return HTTP_CLIENT.put(
    Endpoint.expense.edit.replace(":id", String(id)),
    payload
  );
};

const DeleteExpenseService = (id: number) => {
  return HTTP_CLIENT.delete(Endpoint.expense.delete.replace(":id", String(id)));
};

export {
  AddExpenseService,
  GetAllExpenseService,
  GetExpenseStatsService,
  EditExpenseService,
  DeleteExpenseService,
};
