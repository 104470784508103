import classNames from "classnames";
import styles from "./style.module.scss";
import CustomModal from "shared/components/common/customModal";
import { Icons } from "assets";
import CustomButton from "shared/components/common/customButton";

interface Props extends ModalProps {
  subtitle?: string;
  handleSubmit?: () => void;
  actionButtonText?: string;
  loading?: boolean;
}

const ConfirmationModal = ({
  subtitle,
  actionButtonText,
  loading,
  show,
  handleClose,
  handleSubmit,
}: Props) => {
  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <div
          className={classNames("flex items-center justify-end px-4 mt-4 mb-3")}
        >
          <div
            className={classNames(styles.crossContainer)}
            onClick={() => {
              if (!loading) {
                handleClose();
              }
            }}
          >
            <Icons.Cross />
          </div>
        </div>
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-4")}
        >
          <div className={classNames("flex flex-col gap-2 mb-4")}>
            <h6>Are you sure?</h6>
            <p>{subtitle ? subtitle : "Are you sure you want to delete?"}</p>
          </div>
          <div
            className={classNames(
              "flex items-center w-full justify-between gap-4"
            )}
          >
            <CustomButton
              title="Not Now"
              containerStyle={styles.defaultButton}
              onClick={handleClose}
              disabled={loading}
              style={{ width: "100%" }}
            />
            <CustomButton
              title={actionButtonText ? actionButtonText : "Yes, Delete"}
              containerStyle={styles.actionButton}
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default ConfirmationModal;
