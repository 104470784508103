import { Icons } from "assets";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "shared/components/common/breadCrumb";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import ProfileDropDown from "shared/components/common/profileDropDown";
import LedgerCulling from "shared/components/ledgerComponents/culling";
import LedgerElectricityBill from "shared/components/ledgerComponents/expenses/electricalBill";
import LedgerFeed from "shared/components/ledgerComponents/expenses/feed";
import LedgerFuel from "shared/components/ledgerComponents/expenses/fuel";
import LedgerMedicine from "shared/components/ledgerComponents/expenses/medicine";
import LedgerMisc from "shared/components/ledgerComponents/expenses/misc";
import LedgerSalary from "shared/components/ledgerComponents/expenses/salary";
import LedgerVaccine from "shared/components/ledgerComponents/expenses/vaccine";
import LedgerFlock from "shared/components/ledgerComponents/flock";
import LedgerMorality from "shared/components/ledgerComponents/mortality";
import LedgerProduction from "shared/components/ledgerComponents/production";
import LedgerEggSales from "shared/components/ledgerComponents/sales/eggSale";
import LedgerOtherSales from "shared/components/ledgerComponents/sales/otherSale";
import LedgerTransferSales from "shared/components/ledgerComponents/sales/transferSale";
import { setBreadCrumb } from "shared/redux/reducers/breadCrumbSlice";
import { routeConstant } from "shared/routes/routeConstant";
import { GetLedgerService } from "shared/services/dashboardService";
import styles from "./style.module.scss";

const Legder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleGetLedger = () => {
    setLoading(true);
    GetLedgerService({ startDate, endDate })
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetLedger();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: routeConstant.ledger.title,
          },
        ],
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classNames(styles.customContainer)}>
      <div className={classNames(styles.topLevelContainer)}>
        <div className={classNames("w-full", styles.headerContainer)}>
          <div className={classNames("flex items-center gap-4")}>
            <Icons.ArrowLeft
              onClick={() => {
                navigate(routeConstant.dashboard.path);
              }}
              className="cursor-pointer"
            />
            <BreadCrumb />
          </div>
          <ProfileDropDown />
        </div>
      </div>
      <div className={classNames("flex flex-col gap-10 my-5")}>
        <div
          className={classNames(
            styles.dateWrapper,
            "gap-3 flex-col xs:flex-row items-start xs:items-center w-full"
          )}
        >
          <div className={classNames("flex items-center justify-start gap-3")}>
            <CustomInput
              type="date"
              placeholder="Start Date"
              notRequiredMargin
              isDate
              value={startDate}
              max={new Date().toISOString().split("T")[0]}
              customInputContainer={classNames(styles.inputContainer)}
              onChange={(e) => {
                setStartDate(e.currentTarget.value);
              }}
            />
            <CustomInput
              type="date"
              placeholder="End Date"
              notRequiredMargin
              isDate
              value={endDate}
              max={new Date().toISOString().split("T")[0]}
              customInputContainer={classNames(styles.inputContainer)}
              onChange={(e) => {
                setEndDate(e.currentTarget.value);
              }}
            />
          </div>
          <CustomButton
            title="Search"
            onClick={() => {
              handleGetLedger();
            }}
          />
        </div>
        <LedgerProduction
          productions={data?.production ? data?.production : []}
          loading={loading}
        />
        <LedgerEggSales
          sales={data?.sales?.egg ? data?.sales?.egg : []}
          loading={loading}
        />
        <LedgerTransferSales
          sales={data?.sales?.transfer ? data?.sales?.transfer : []}
          loading={loading}
        />
        <LedgerOtherSales
          sales={data?.sales?.other ? data?.sales?.other : []}
          loading={loading}
        />
        <LedgerElectricityBill
          expenses={data?.expenses?.bill ? data?.expenses?.bill : []}
          loading={loading}
        />
        <LedgerFeed
          expenses={data?.expenses?.feed ? data?.expenses?.feed : []}
          loading={loading}
        />
        <LedgerFuel
          expenses={data?.expenses?.fuel ? data?.expenses?.fuel : []}
          loading={loading}
        />
        <LedgerMedicine
          expenses={data?.expenses?.medicine ? data?.expenses?.medicine : []}
          loading={loading}
        />
        <LedgerMisc
          expenses={data?.expenses?.misc ? data?.expenses?.misc : []}
          loading={loading}
        />
        <LedgerSalary
          expenses={data?.expenses?.salary ? data?.expenses?.salary : []}
          loading={loading}
        />
        <LedgerVaccine
          expenses={data?.expenses?.vaccine ? data?.expenses?.vaccine : []}
          loading={loading}
        />
        <LedgerFlock
          expenses={data?.expenses?.flock ? data?.expenses?.flock : []}
          loading={loading}
        />
        <LedgerMorality
          mortalities={data?.mortality ? data?.mortality : []}
          loading={loading}
        />
        <LedgerCulling
          cullings={data?.culling ? data?.culling : []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Legder;
