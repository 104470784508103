import { Icons } from "assets";
import { routeConstant } from "shared/routes/routeConstant";

const sideBarConstants: SideBarItemsType[] = [
  {
    title: "Main",
    paths: [
      {
        path: routeConstant.dashboard.path,
        title: routeConstant.dashboard.title,
        Icon: Icons.Dashboard,
      },
      {
        path: routeConstant.ledger.path,
        title: routeConstant.ledger.title,
        Icon: Icons.DashLedger,
      },
      {
        path: routeConstant.production.path,
        title: routeConstant.production.title,
        Icon: Icons.DashTags,
      },
      {
        title: "Sales",
        Icon: Icons.DashSales,
        path: routeConstant.sales.path,
        subPaths: [
          {
            path: routeConstant.eggSales.path,
            title: routeConstant.eggSales.title,
            Icon: Icons.DashEggSales,
          },
          {
            path: routeConstant.transferSales.path,
            title: routeConstant.transferSales.title,
            Icon: Icons.DashTransferSales,
          },
          {
            path: routeConstant.otherSales.path,
            title: routeConstant.otherSales.title,
            Icon: Icons.DashOtherSales,
          },
        ],
      },
      {
        path: routeConstant.stock.path,
        title: routeConstant.stock.title,
        Icon: Icons.DashStock,
      },
      {
        title: "Expenses",
        Icon: Icons.DashExpenses,
        path: routeConstant.expenses.path,
        subPaths: [
          {
            path: routeConstant.misc.path,
            title: routeConstant.misc.title,
            Icon: Icons.DashMisc,
          },
          {
            path: routeConstant.feed.path,
            title: routeConstant.feed.title,
            Icon: Icons.DashFeed,
          },
          {
            path: routeConstant.electicalBill.path,
            title: routeConstant.electicalBill.title,
            Icon: Icons.DashElectricity,
          },
          {
            path: routeConstant.vaccine.path,
            title: routeConstant.vaccine.title,
            Icon: Icons.DashVaccine,
          },
          {
            path: routeConstant.medicine.path,
            title: routeConstant.medicine.title,
            Icon: Icons.DashMedicine,
          },
          {
            path: routeConstant.fuel.path,
            title: routeConstant.fuel.title,
            Icon: Icons.DashFuel,
          },
          {
            path: routeConstant.salary.path,
            title: routeConstant.salary.title,
            Icon: Icons.DashSalary,
          },
        ],
      },
      {
        path: routeConstant.mortality.path,
        title: routeConstant.mortality.title,
        Icon: Icons.DashMorality,
      },
      {
        path: routeConstant.flock.path,
        title: routeConstant.flock.title,
        Icon: Icons.DashFlock,
      },
      {
        path: routeConstant.culling.path,
        title: routeConstant.culling.title,
        Icon: Icons.DashCulling,
      },
    ],
  },
  {
    title: "Help",
    paths: [
      {
        path: routeConstant.settings.path,
        title: routeConstant.settings.title,
        Icon: Icons.DashSettings,
      },
    ],
  },
];

const SettingsTabs = ["Account", "Change Password"];
const SettingsTabsEnums = {
  account: "Account",
  changePassword: "Change Password",
};

export { sideBarConstants, SettingsTabs, SettingsTabsEnums };
