import classNames from "classnames";
import { useFormik } from "formik";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomModal from "shared/components/common/customModal";
import ModalHeader from "shared/components/common/modalHeader";
import { toastMessage } from "shared/components/common/toast";
import {
  AddProductionService,
  EditProductionService,
} from "shared/services/productionService";
import { AddProductionVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import moment from "moment";

interface InitialValuesProps {
  description: string;
  size: string;
  patee: number;
  tray: number;
  date: string;
}

interface AddProductionModalProps extends ModalProps {
  isEdit?: boolean;
  productionItem?: any;
  productionsList?: any[];
  setProductionsList?: (list: any[]) => void;
}

const AddProductionModal = ({
  isEdit,
  productionItem,
  productionsList,
  show,
  handleClose,
  setProductionsList,
}: AddProductionModalProps) => {
  const initialValues: InitialValuesProps = {
    date: productionItem?.date
      ? moment(productionItem?.date).format("YYYY-MM-DD")
      : "",
    description: productionItem?.description || "",
    size: productionItem?.size || "",
    patee: productionItem?.tray ? Math.floor(productionItem?.tray / 12) : 0,
    tray: productionItem?.tray ? productionItem?.tray % 12 : 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddProductionVS,
    onSubmit: (value, action) => {
      handleProduction();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleProduction = () => {
    setSubmitting(true);

    if (isEdit) {
      EditProductionService(values, productionItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Production updated successfully");
          if (productionItem) {
            const updatedList: any = productionsList?.map((item) => {
              if (item._id === productionItem._id) {
                return data;
              }
              return item;
            });
            setProductionsList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddProductionService(values)
        .then(({ data }) => {
          toastMessage("success", "Production added successfully");
          if (productionsList) {
            let tempList = [...productionsList];
            tempList.unshift(data);
            setProductionsList?.(tempList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Production"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <CustomInput
              label="Date"
              placeholder="1/11/2021"
              required
              isDate
              type="date"
              value={values.date}
              error={touched.date && errors.date ? errors.date : ""}
              onChange={handleChange("date")}
            />
            <CustomInput
              label="Details"
              placeholder="Write something"
              required
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
            <CustomInput
              label="Size"
              placeholder="Size"
              required
              value={values.size}
              error={touched.size && errors.size ? errors.size : ""}
              onChange={handleChange("size")}
            />
            <CustomInput
              label="Patee"
              placeholder="number of patee"
              required
              isNumber
              value={values.patee}
              error={touched.patee && errors.patee ? errors.patee : ""}
              formikKey="patee"
              setFieldValue={setFieldValue}
            />
            <CustomInput
              label="Tray"
              placeholder="number of trays"
              required
              isNumber
              value={values.tray}
              error={touched.tray && errors.tray ? errors.tray : ""}
              formikKey="tray"
              setFieldValue={setFieldValue}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddProductionModal;
