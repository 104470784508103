const BaseURL = "https://api.mpspk.com/api/";
// const BaseURL = "http://localhost:8000/api/";

const Endpoint = {
  auth: {
    login: "users/login",
    sendOTP: "users/send-otp",
    verifyOTP: "users/verify-otp",
    resetPassword: "users/password-reset",
  },
  dashboard: {
    stats: "general/stats",
    ledger: "general/ledger",
  },
  user: {
    edit: "users/edit",
    changePassword: "users/change-password",
  },
  production: {
    get: "productions/get-productions",
    add: "productions/create",
    edit: "productions/edit/:id",
    delete: "productions/:id",
    stats: "productions/get-production-stats",
  },
  sales: {
    get: "sales/get-sales/:type",
    add: "sales/create",
    edit: "sales/edit/:id",
    delete: "sales/:id",
    stats: "sales/get-sales-stats/:type",
  },
  stocks: {
    get: "stocks/get-stocks",
  },
  mortality: {
    get: "mortality/get-mortalities",
    add: "mortality/create",
    edit: "mortality/edit/:id",
    delete: "mortality/:id",
    stats: "mortality/get-mortalities-stats",
  },
  expense: {
    get: "expenses/get-expenses/:type",
    add: "expenses/create",
    edit: "expenses/edit/:id",
    delete: "expenses/:id",
    stats: "expenses/get-expenses-stats/:type",
  },
  culling: {
    get: "culling/get-cullings",
    add: "culling/create",
    edit: "culling/edit/:id",
    delete: "culling/:id",
    stats: "culling/get-cullings-stats",
  },
};

export { Endpoint, BaseURL };
