import classNames from "classnames";
import { useFormik } from "formik";
import styles from "./style.module.scss";
import CustomInput from "shared/components/common/customInput";
import { Icons } from "assets";
import CustomButton from "shared/components/common/customButton";
import { UpdatePasswordVS } from "shared/utils/validation";
import { ChangePasswordService } from "shared/services/userService";
import { toastMessage } from "shared/components/common/toast";

const initialValues: UpdatePasswordType = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UpdatePasswordVS,
    onSubmit: () => {
      handleUpdatePassword();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = formik;

  const handleUpdatePassword = () => {
    setSubmitting(true);
    let formData = {
      currentPassword: values.oldPassword,
      newPassword: values.password,
    };
    ChangePasswordService(formData)
      .then(({ data }) => {
        resetForm();
        toastMessage("success", "Password Updated Successfully");
      })
      .catch((err) => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classNames("flex flex-col items-start gap-6 w-full")}>
      <h3 className={classNames(styles.title)}>Change Password</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classNames(
          "flex flex-col gap-2 w-full",
          styles.formContainer
        )}
      >
        <CustomInput
          label="Current Password"
          placeholder="• • • • • •"
          required
          type="password"
          isPassword
          Icon={Icons.Lock}
          value={values.oldPassword}
          error={
            touched.oldPassword && errors.oldPassword ? errors.oldPassword : ""
          }
          onChange={handleChange("oldPassword")}
        />
        <CustomInput
          label="New Password "
          placeholder="• • • • • •"
          required
          type="password"
          isPassword
          Icon={Icons.Lock}
          value={values.password}
          error={touched.password && errors.password ? errors.password : ""}
          onChange={handleChange("password")}
        />

        <CustomInput
          label="Confirm Password"
          placeholder="• • • • • •"
          required
          type="password"
          isPassword
          Icon={Icons.Lock}
          value={values.confirmPassword}
          error={
            touched.confirmPassword && errors.confirmPassword
              ? errors.confirmPassword
              : ""
          }
          onChange={handleChange("confirmPassword")}
        />

        <CustomButton
          title="Save Changes"
          containerStyle={classNames("self-end")}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default ChangePassword;
