import classNames from "classnames";
import moment from "moment";
import CustomTable from "shared/components/common/customTable";
import SekeletonLoader from "shared/loaders/skeleton";
import styles from "../../style.module.scss";

interface SalesProps {
  sales: any[];
  loading: boolean;
}

const LedgerEggSales = ({ sales, loading }: SalesProps) => {
  return (
    <div className={classNames(styles.tableMain, "mb-4")}>
      <div
        className={classNames(
          "flex items-start gap-3 lg:items-center justify-between flex-col lg:flex-row px-4 py-4"
        )}
      >
        <div className={classNames("flex flex-col items-start")}>
          <h1 className={classNames(styles.bookMainHeading)}>Sales</h1>
          <p className={classNames(styles.bookMainTitle)}>
            Showing sales list here.
          </p>
        </div>
      </div>
      <CustomTable
        title="Sales"
        heads={[
          "Sr#",
          "Date",
          "Description",
          "Patee",
          "Per Patee",
          "Tray",
          "Per Tray",
          "Broken Tray",
          "Per Broken Tray",
          "Waste Sales",
          "Total Sales Amount",
        ]}
        loading={loading}
        isEmpty={sales.length === 0}
      >
        {loading ? (
          <>
            {Array.from(Array(5).keys()).map((_, index) => {
              return <TableRowLoader key={index} />;
            })}
          </>
        ) : (
          <>
            {sales.map((sale, index) => {
              return <TableRow item={sale} key={index} index={index} />;
            })}
          </>
        )}
      </CustomTable>
    </div>
  );
};

const TableRow = ({ item, index }: any) => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ width: "4%" }}
        >
          {index + 1}
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "8%" }}
        >
          {moment(item.date).format("DD-MM-YYYY")}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "20%" }}
        >
          {item?.description}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "4%" }}
        >
          {item?.patee}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "5%" }}
        >
          {item?.perPatee}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "4%" }}
        >
          {item?.tray}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "5%" }}
        >
          {item?.perTray}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "6%" }}
        >
          {item?.brokenTray}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "12%" }}
        >
          {item?.perBrokenTray}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "8%" }}
        >
          Rs. {item?.waste}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "14%" }}
        >
          Rs. {item?.amount}
        </td>
      </tr>
    </>
  );
};

const TableRowLoader = () => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ width: "4%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "8%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "20%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "4%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "5%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "4%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "5%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "6%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "12%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "8%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "14%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
      </tr>
    </>
  );
};

export default LedgerEggSales;
