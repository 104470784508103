import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import styles from "../style.module.scss";
import { useEffect, useState } from "react";
import { Icons } from "assets";
import { isExpensesRoute, isSalesRoute } from "shared/utils/helper";
import { routeConstant } from "shared/routes/routeConstant";

interface navItemInterface extends SideBarItemPathType {
  setIsOpen: (val: boolean) => void;
}
const NavItem = (props: navItemInterface) => {
  const { title, Icon, path, setIsOpen, subPaths } = props;
  const location = useLocation();
  const [showSubPaths, setShowSubPaths] = useState<boolean>(false);
  const [activePath, setActivePath] = useState<string>(location.pathname);

  useEffect(() => {
    if (isExpensesRoute(location.pathname)) {
      if (props.path === routeConstant.expenses.path) {
        setShowSubPaths(true);
      } else {
        setShowSubPaths(false);
      }
    } else if (isSalesRoute(location.pathname)) {
      if (props.path === routeConstant.sales.path) {
        setShowSubPaths(true);
      } else {
        setShowSubPaths(false);
      }
    } else {
      setShowSubPaths(false);
    }

    setActivePath(location.pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {subPaths ? (
        <>
          <div
            className={classNames(
              showSubPaths
                ? styles.activeSubNavItemContainer
                : styles.navItemContainer,
              "w-full pr-3 justify-between"
            )}
            onClick={() => {
              setShowSubPaths(!showSubPaths);
            }}
          >
            <div className={classNames("flex items-center")}>
              {Icon ? (
                <img src={Icon} alt="" className={classNames(styles.icon)} />
              ) : null}

              <label className={classNames(styles.heading)} role="button">
                {title}
              </label>
            </div>
            {subPaths ? <Icons.ChevDown /> : null}
          </div>
          {showSubPaths ? (
            <div
              className={classNames(
                styles.subPathsList,
                "w-full pr-3 justify-between"
              )}
            >
              {subPaths.map((subPath: SideBarItemPathType, index: number) => (
                <Link
                  key={index}
                  to={subPath.path ? subPath.path : ""}
                  className={classNames(
                    activePath === subPath.path
                      ? styles.activeNavItemContainer
                      : styles.navItemContainer,
                    "w-full pr-3 justify-between"
                  )}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <div className={classNames("flex items-center")}>
                    {subPath.Icon ? (
                      <img
                        src={subPath.Icon}
                        alt=""
                        className={classNames(styles.icon)}
                      />
                    ) : null}

                    <label className={classNames(styles.heading)} role="button">
                      {subPath.title}
                    </label>
                  </div>
                </Link>
              ))}
            </div>
          ) : null}
        </>
      ) : (
        <Link
          className={classNames(
            activePath === path
              ? styles.activeNavItemContainer
              : styles.navItemContainer,
            "w-full pr-3 justify-between"
          )}
          to={path ? path : ""}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <div className={classNames("flex items-center")}>
            {Icon ? (
              <img src={Icon} alt="" className={classNames(styles.icon)} />
            ) : null}

            <label className={classNames(styles.heading)} role="button">
              {title}
            </label>
          </div>
          {subPaths ? <Icons.ChevDown /> : null}
        </Link>
      )}
    </>
  );
};

export default NavItem;
