import classNames from "classnames";
import styles from "./style.module.scss";
import SekeletonLoader from "shared/loaders/skeleton";

interface StatsCardProps {
  title: string;
  borderColor?: string;
  stats: {
    title: string;
    value: string;
  }[];
  titleBg?: string;
  loading?: boolean;
}

const StatsCard = ({
  title,
  stats,
  borderColor,
  titleBg,
  loading,
}: StatsCardProps) => {
  return (
    <div
      className={classNames(styles.cardContainer, "flex flex-col gap-3")}
      style={borderColor ? { borderLeftColor: borderColor } : {}}
    >
      <h1 style={titleBg ? { background: titleBg, color: "white" } : {}}>
        {title}
      </h1>
      {stats?.length > 0 ? (
        <>
          <hr />
          <div className={classNames("flex items-center justify-between")}>
            {stats?.map((stat, index) => {
              return (
                <div className={classNames("flex flex-col")} key={index}>
                  <label>{stat?.title}</label>
                  {loading ? (
                    <SekeletonLoader
                      className={classNames(styles.statsLoader)}
                    />
                  ) : (
                    <span>{stat?.value}</span>
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default StatsCard;
