import classNames from "classnames";
import moment from "moment";
import CustomTable from "shared/components/common/customTable";
import SekeletonLoader from "shared/loaders/skeleton";
import styles from "../style.module.scss";

interface ProductionProps {
  productions: any[];
  loading: boolean;
}

const LedgerProduction = ({ productions, loading }: ProductionProps) => {
  return (
    <div className={classNames(styles.tableMain, "mb-4")}>
      <div
        className={classNames(
          "flex items-start gap-3 lg:items-center justify-between flex-col lg:flex-row px-4 py-4"
        )}
      >
        <div className={classNames("flex flex-col items-start")}>
          <h1 className={classNames(styles.bookMainHeading)}>Production</h1>
          <p className={classNames(styles.bookMainTitle)}>
            Showing production list here.
          </p>
        </div>
      </div>
      <CustomTable
        title="Production"
        heads={["Sr#", "Date", "Details", "Size", "Patee", "Tray"]}
        loading={loading}
        isEmpty={productions.length === 0}
      >
        {loading ? (
          <>
            {Array.from(Array(5).keys()).map((_, index) => {
              return <TableRowLoader key={index} />;
            })}
          </>
        ) : (
          <>
            {productions.map((production, index) => {
              return <TableRow item={production} key={index} index={index} />;
            })}
          </>
        )}
      </CustomTable>
    </div>
  );
};

const TableRow = ({ item, index }: any) => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ verticalAlign: "middle", width: "8%" }}
        >
          {index + 1}
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          {moment(item.date).format("DD-MM-YYYY")}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "22%" }}
        >
          {item.description}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          {item.size}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          {Math.floor(item?.tray / 12)}
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          {item?.tray % 12}
        </td>
      </tr>
    </>
  );
};

const TableRowLoader = () => {
  return (
    <>
      <tr>
        <td
          className={classNames(styles.tableContentLabel, "ps-4 pe-2 py-3")}
          style={{ verticalAlign: "middle", width: "8%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>

        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "10%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "22%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
        <td
          className={classNames(styles.tableContentLabel, "px-2 py-3")}
          style={{ width: "15%" }}
        >
          <SekeletonLoader className={classNames(styles.lineLoader)} />
        </td>
      </tr>
    </>
  );
};

export default LedgerProduction;
