import * as yup from "yup";

const passwordRegExp = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/;

const LoginVS = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email")
    .label("email"),
  password: yup.string().required("Password is Required").label("password"),
});

const UpdatePasswordVS = yup.object().shape({
  oldPassword: yup.string().required("Current Password is Required"),
  password: yup
    .string()
    .required("New Password is Required")
    .matches(
      passwordRegExp,
      "Password must contain at least One Upper Case Character, One Lower Case Character, One Special Character and One Number"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const UpdateProfileVS = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name  is Required")
    .label("firstName"),
  lastName: yup.string().required("Last Name  is Required").label("lastName"),
  profilePic: yup.mixed().label("profilePic"),
  phoneNumber: yup
    .string()
    .required("Phone Number is Required")
    .label("phoneNumber"),
});

const AddProductionVS = yup.object().shape({
  description: yup
    .string()
    .required("Details  is Required")
    .label("description"),
  size: yup.string().required("Size  is Required").label("size"),
  patee: yup.number().required("Number of Patee is Required").label("patee"),
  tray: yup.number().required("Number of Trays is Required").label("tray"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddSalesVS = yup.object().shape({
  description: yup
    .string()
    .required("Description  is Required")
    .label("description"),
  patee: yup.number().required("Number of Patee is Required").label("patee"),
  perPatee: yup
    .number()
    .required("Per Patee Price is Required")
    .label("perPatee"),
  tray: yup.number().required("Number of Trays is Required").label("tray"),
  perTray: yup.number().required("Per Tray Price is Required").label("perTray"),
  brokenTray: yup
    .number()
    .required("Number of Broken Trays is Required")
    .label("brokenTray"),
  perBrokenTray: yup
    .number()
    .required("Per Broken Tray Price is Required")
    .label("perBrokenTray"),
  waste: yup.number().required("Waste Sales is Required").label("waste"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddTransferSalesVS = yup.object().shape({
  description: yup
    .string()
    .required("Description  is Required")
    .label("description"),
  flock_quantity: yup
    .number()
    .required("Flock Quantity is Required")
    .label("flock_quantity"),
  feed_quantity: yup
    .number()
    .required("Feed Quantity is Required")
    .label("feed_quantity"),
  amount: yup.number().required("Amount is Required").label("amount"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddOtherSalesVS = yup.object().shape({
  description: yup
    .string()
    .required("Description  is Required")
    .label("description"),
  amount: yup.number().required("Amount is Required").label("amount"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddMoralityVS = yup.object().shape({
  description: yup
    .string()
    .required("Description  is Required")
    .label("description"),
  rate: yup.number().required("Rate is Required").label("rate"),
  quantity: yup.number().required("Quantity is Required").label("quantity"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddElectricityVS = yup.object().shape({
  description: yup.string().required("Description  is Required").label("desc"),
  bill_type: yup.string().required("Bill Type is Required").label("bill_type"),
  amount: yup.number().required("Amount is Required").label("amount"),
  date: yup.string().required("Date is Required").label("date"),
});

const AddSalaryVS = yup.object().shape({
  name: yup.string().required("Name is Required").label("name"),
  date: yup.string().required("Date is Required").label("date"),
  workDays: yup.number().required("Work Days are Required").label("workDays"),
  salary: yup.number().required("Salary is Required").label("salary"),
});

export {
  LoginVS,
  UpdatePasswordVS,
  UpdateProfileVS,
  AddProductionVS,
  AddSalesVS,
  AddMoralityVS,
  AddElectricityVS,
  AddSalaryVS,
  AddTransferSalesVS,
  AddOtherSalesVS,
};
