import classNames from "classnames";
import { useEffect, useState } from "react";
import StatsCard from "shared/components/statsCard";
import { GetStocksService } from "shared/services/stocksService";

const Stock = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [stock, setStock] = useState<any>(null);

  const handleGetStocks = () => {
    GetStocksService()
      .then(({ data }) => {
        setStock(data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetStocks();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classNames("flex flex-col items-start w-full gap-10")}>
      <div className="w-full">
        <StatsCard
          title="Production"
          stats={[
            {
              title: "Patee",
              value: `${
                stock?.production ? Math.floor(stock?.production / 12) : "0"
              }`,
            },
            {
              title: "Tray",
              value: `${stock?.production ? stock?.production % 12 : "0"}`,
            },
          ]}
          borderColor="#3498db"
          loading={loading}
        />
      </div>
      <div className="w-full">
        <StatsCard
          title="Sales"
          stats={[
            {
              title: "Patee",
              value: `${stock?.sales ? Math.floor(stock?.sales / 12) : "0"}`,
            },
            {
              title: "Tray",
              value: `${stock?.sales ? stock?.sales % 12 : "0"}`,
            },
          ]}
          borderColor="#0b8457"
          loading={loading}
        />
      </div>
      <div className="w-full">
        <StatsCard
          title="Stock"
          stats={[
            {
              title: "Patee",
              value: `${
                stock?.production
                  ? Math.floor((stock?.production - stock?.sales) / 12)
                  : "0"
              }`,
            },
            {
              title: "Tray",
              value: `${
                stock?.production
                  ? (stock?.production - stock?.sales) % 12
                  : "0"
              }`,
            },
          ]}
          borderColor="#e84a5f"
          loading={loading}
        />
      </div>
      <div className="w-full">
        <StatsCard
          title="Flock"
          stats={[
            {
              title: "Total Birds",
              value: `${stock?.flock ? stock?.flock : "0"}`,
            },
          ]}
          borderColor="#eac100"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Stock;
