const routeConstant = {
  login: {
    path: "/",
    title: "Login",
  },
  forgotPassword: {
    path: "/forgot-password",
    title: "Forgot Password",
  },
  resetPassword: {
    path: "/reset-password",
    title: "Reset Password",
  },
  otp: {
    path: "/otp",
    title: "OTP",
  },
  dashboard: {
    path: "/dashboard",
    title: "Dashboard",
  },
  production: {
    path: "/production",
    title: "Production",
  },
  eggSales: {
    path: "/egg-sales",
    title: "Egg Sales",
  },
  otherSales: {
    path: "/other-sales",
    title: "Other Sales",
  },
  transferSales: {
    path: "/transfer-sales",
    title: "Transfer Sales",
  },
  stock: {
    path: "/stock",
    title: "Stock",
  },
  sales: {
    path: "/sales",
    title: "Sales",
  },
  expenses: {
    path: "/expenses",
    title: "Expenses",
  },
  misc: {
    path: "/misc",
    title: "Miscellaneous",
  },
  feed: {
    path: "/feed",
    title: "Feed",
  },
  electicalBill: {
    path: "/electrical-bill",
    title: "Electricity Bill",
  },
  vaccine: {
    path: "/vaccine",
    title: "Vaccine",
  },
  medicine: {
    path: "/medicine",
    title: "Medicine",
  },
  fuel: {
    path: "/fuel",
    title: "Fuel",
  },
  salary: {
    path: "/salary",
    title: "Salary",
  },
  mortality: {
    path: "/mortality",
    title: "Mortality",
  },
  flock: {
    path: "/flock",
    title: "Flock",
  },
  culling: {
    path: "/culling",
    title: "Culling",
  },
  settings: {
    path: "/settings",
    title: "Settings",
  },
  ledger: {
    path: "/ledger",
    title: "Ledger",
  },
};
export { routeConstant };
