import classNames from "classnames";
import { useFormik } from "formik";
import styles from "./style.module.scss";
import CustomModal from "shared/components/common/customModal";
import ModalHeader from "shared/components/common/modalHeader";
import CustomInput from "shared/components/common/customInput";
import CustomButton from "shared/components/common/customButton";
import { AddSalesVS } from "shared/utils/validation";
import CustomTextArea from "shared/components/common/customTextArea";
import {
  AddSalesService,
  EditSalesService,
} from "shared/services/salesService";
import { toastMessage } from "shared/components/common/toast";
import moment from "moment";
import { SALES_TYPES } from "shared/utils/enum";

interface InitialValuesProps {
  description: string;
  patee: number;
  perPatee: number;
  tray: number;
  perTray: number;
  brokenTray: number;
  perBrokenTray: number;
  waste: number;
  date: string;
  type: number;
}

interface AddSalesModalProps extends ModalProps {
  saleItem?: any;
  salesList?: any[];
  setSalesList?: (list: any[]) => void;
  isEdit?: boolean;
}

const AddSalesModal = ({
  saleItem,
  salesList,
  isEdit,
  show,
  handleClose,
  setSalesList,
}: AddSalesModalProps) => {
  const initialValues: InitialValuesProps = {
    date: saleItem?.date ? moment(saleItem?.date).format("YYYY-MM-DD") : "",
    description: saleItem?.description || "",
    patee: saleItem?.patee || 0,
    perPatee: saleItem?.perPatee || 0,
    tray: saleItem?.tray || 0,
    perTray: saleItem?.perTray || 0,
    brokenTray: saleItem?.brokenTray || 0,
    perBrokenTray: saleItem?.perBrokenTray || 0,
    waste: saleItem?.waste || 0,
    type: SALES_TYPES.egg,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddSalesVS,
    onSubmit: (value, action) => {
      handleSale();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleSale = () => {
    setSubmitting(true);
    if (isEdit) {
      EditSalesService(values, saleItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Sales updated successfully");
          if (saleItem) {
            const updatedList: any = salesList?.map((item) => {
              if (item._id === saleItem._id) {
                return data;
              }
              return item;
            });
            setSalesList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddSalesService(values)
        .then(({ data }) => {
          toastMessage("success", "Sales added successfully");
          if (salesList) {
            let tempList = [...salesList];
            tempList.unshift(data);
            setSalesList?.(tempList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Sales"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <div className={classNames("flex items-center gap-3")}>
              <CustomInput
                label="Patee"
                placeholder="number of patee"
                required
                isNumber
                value={values.patee}
                error={touched.patee && errors.patee ? errors.patee : ""}
                formikKey="patee"
                setFieldValue={setFieldValue}
              />
              <CustomInput
                label="Per Patee"
                placeholder="per patee price"
                required
                isNumber
                value={values.perPatee}
                error={
                  touched.perPatee && errors.perPatee ? errors.perPatee : ""
                }
                formikKey="perPatee"
                setFieldValue={setFieldValue}
              />
            </div>
            <div className={classNames("flex items-center gap-3")}>
              <CustomInput
                label="Tray"
                placeholder="number of trays"
                required
                isNumber
                value={values.tray}
                error={touched.tray && errors.tray ? errors.tray : ""}
                formikKey="tray"
                setFieldValue={setFieldValue}
              />
              <CustomInput
                label="Per Tray"
                placeholder="per tray price"
                required
                isNumber
                value={values.perTray}
                error={touched.perTray && errors.perTray ? errors.perTray : ""}
                formikKey="perTray"
                setFieldValue={setFieldValue}
              />
            </div>

            <div className={classNames("flex items-center gap-3")}>
              <CustomInput
                label="Broken Tray"
                placeholder="number of broken trays"
                required
                isNumber
                value={values.brokenTray}
                error={
                  touched.brokenTray && errors.brokenTray
                    ? errors.brokenTray
                    : ""
                }
                formikKey="brokenTray"
                setFieldValue={setFieldValue}
              />
              <CustomInput
                label="Per Broken Tray"
                placeholder="per broken tray price"
                required
                isNumber
                value={values.perBrokenTray}
                error={
                  touched.perBrokenTray && errors.perBrokenTray
                    ? errors.perBrokenTray
                    : ""
                }
                formikKey="perBrokenTray"
                setFieldValue={setFieldValue}
              />
            </div>
            <div className={classNames("flex items-center gap-3")}>
              <CustomInput
                label="Waste Sales"
                placeholder="waste sales"
                required
                isNumber
                value={values.waste}
                error={touched.waste && errors.waste ? errors.waste : ""}
                formikKey="waste"
                setFieldValue={setFieldValue}
              />
              <CustomInput
                label="Date"
                placeholder="1/11/2021"
                required
                isDate
                type="date"
                value={values.date}
                error={touched.date && errors.date ? errors.date : ""}
                onChange={handleChange("date")}
              />
            </div>
            <CustomTextArea
              label="Description"
              placeholder="Write something"
              required
              customInputContainer={classNames(styles.customInputContainer)}
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddSalesModal;
