const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const EXPENSES_TYPES = {
  misc: 1,
  feed: 2,
  bill: 3,
  vaccine: 4,
  medicine: 5,
  fuel: 6,
  salary: 7,
  flock: 8,
};

const BILL_TYPES = {
  electricity: 1,
  gas: 2,
};

const SALES_TYPES = {
  egg: 1,
  transfer: 2,
  other: 3,
};

export { SUPPORTED_FORMATS, EXPENSES_TYPES, BILL_TYPES, SALES_TYPES };
