import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "shared/utils/interceptor";

interface CullingProps {
  description: string;
  rate: number;
  quantity: number;
}

const AddCullingService = (payload: CullingProps) => {
  return HTTP_CLIENT.post(Endpoint.culling.add, payload);
};

const GetAllCullingService = (
  search: string,
  startDate: string,
  endDate: string
) => {
  return HTTP_CLIENT.get(
    Endpoint.culling.get +
      `${
        search
          ? `?search=${search}&startDate=${startDate}&endDate=${endDate}`
          : `?startDate=${startDate}&endDate=${endDate}`
      }`
  );
};

const GetCullingStatsService = ({ startDate, endDate }: FilterType) => {
  return HTTP_CLIENT.get(
    Endpoint.culling.stats + `?startDate=${startDate}&endDate=${endDate}`
  );
};

const EditCullingService = (payload: CullingProps, id: number) => {
  return HTTP_CLIENT.put(
    Endpoint.culling.edit.replace(":id", String(id)),
    payload
  );
};

const DeleteCullingService = (id: number) => {
  return HTTP_CLIENT.delete(Endpoint.culling.delete.replace(":id", String(id)));
};

export {
  AddCullingService,
  GetAllCullingService,
  GetCullingStatsService,
  EditCullingService,
  DeleteCullingService,
};
