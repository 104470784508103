import { Icons } from "assets";
import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setBreadCrumb } from "shared/redux/reducers/breadCrumbSlice";
import { findScreenTitle } from "shared/utils/helper";
import BreadCrumb from "../breadCrumb";
import ProfileDropDown from "../profileDropDown";
import styles from "./style.module.scss";

interface Props {
  openMobile: () => void;
}

function DashboardHeader({ openMobile }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumb({
        crumbs: [
          {
            title: findScreenTitle(location.pathname),
          },
        ],
      })
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <div className={classNames(styles.topLevelContainer)}>
        <div className={classNames("w-full", styles.headerContainer)}>
          <div className={classNames("flex items-center gap-4")}>
            <Icons.Hamburger
              className={classNames(styles.hamburgerIcon)}
              onClick={openMobile}
            />
            <BreadCrumb />
          </div>

          <ProfileDropDown />
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;
