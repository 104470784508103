import Login from "pages/auth/login";
import { routeConstant } from "./routeConstant";
import OTP from "pages/auth/otp";
import ResetPassword from "pages/auth/resetPassword";
import ForgotPassword from "pages/auth/forgot";
import Dashboard from "pages/general/dashboard";
import Settings from "pages/general/settings";
import Production from "pages/general/production";
import Stock from "pages/general/stock";
import Morality from "pages/general/morality";
import Flock from "pages/general/flock";
import Culling from "pages/general/culling";
import Misc from "pages/general/expenses/misc";
import Feed from "pages/general/expenses/feed";
import ElectricityBill from "pages/general/expenses/electricalBill";
import Vaccine from "pages/general/expenses/vaccine";
import Medicine from "pages/general/expenses/medicine";
import Fuel from "pages/general/expenses/fuel";
import Salary from "pages/general/expenses/salary";
import EggSales from "pages/general/sales/eggSale";
import OtherSales from "pages/general/sales/otherSale";
import TransferSales from "pages/general/sales/transferSale";
import Legder from "pages/general/ledger";

const publicRoute = [
  {
    path: routeConstant.login.path,
    title: routeConstant.login.title,
    Component: Login,
  },
  {
    path: routeConstant.forgotPassword.path,
    title: routeConstant.forgotPassword.title,
    Component: ForgotPassword,
  },
  {
    path: routeConstant.resetPassword.path,
    title: routeConstant.resetPassword.title,
    Component: ResetPassword,
  },
  {
    path: routeConstant.otp.path,
    title: routeConstant.otp.title,
    Component: OTP,
  },
];
const privateRoute = [
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: Dashboard,
  },
  {
    path: routeConstant.production.path,
    title: routeConstant.production.title,
    Component: Production,
  },
  {
    path: routeConstant.eggSales.path,
    title: routeConstant.eggSales.title,
    Component: EggSales,
  },
  {
    path: routeConstant.otherSales.path,
    title: routeConstant.otherSales.title,
    Component: OtherSales,
  },
  {
    path: routeConstant.transferSales.path,
    title: routeConstant.transferSales.title,
    Component: TransferSales,
  },
  {
    path: routeConstant.stock.path,
    title: routeConstant.stock.title,
    Component: Stock,
  },
  {
    path: routeConstant.misc.path,
    title: routeConstant.misc.title,
    Component: Misc,
  },
  {
    path: routeConstant.feed.path,
    title: routeConstant.feed.title,
    Component: Feed,
  },
  {
    path: routeConstant.electicalBill.path,
    title: routeConstant.electicalBill.title,
    Component: ElectricityBill,
  },
  {
    path: routeConstant.vaccine.path,
    title: routeConstant.vaccine.title,
    Component: Vaccine,
  },
  {
    path: routeConstant.medicine.path,
    title: routeConstant.medicine.title,
    Component: Medicine,
  },
  {
    path: routeConstant.fuel.path,
    title: routeConstant.fuel.title,
    Component: Fuel,
  },
  {
    path: routeConstant.salary.path,
    title: routeConstant.salary.title,
    Component: Salary,
  },
  {
    path: routeConstant.mortality.path,
    title: routeConstant.mortality.title,
    Component: Morality,
  },
  {
    path: routeConstant.flock.path,
    title: routeConstant.flock.title,
    Component: Flock,
  },
  {
    path: routeConstant.culling.path,
    title: routeConstant.culling.title,
    Component: Culling,
  },
  {
    path: routeConstant.settings.path,
    title: routeConstant.settings.title,
    Component: Settings,
  },
  {
    path: routeConstant.ledger.path,
    title: routeConstant.ledger.title,
    Component: Legder,
  },
];

export { publicRoute, privateRoute };
