import classNames from "classnames";
import { Icons } from "assets";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isExpensesRoute, isSalesRoute } from "shared/utils/helper";
import styles from "../style.module.scss";
import { routeConstant } from "shared/routes/routeConstant";

const NavItem = (props: SideBarItemPathType) => {
  const { title, Icon, path, subPaths } = props;
  const location = useLocation();
  const [activePath, setActivePath] = useState<string>(location.pathname);
  const [showSubPaths, setShowSubPaths] = useState<boolean>(false);

  useEffect(() => {
    if (isExpensesRoute(location.pathname)) {
      if (props.path === routeConstant.expenses.path) {
        setShowSubPaths(true);
      } else {
        setShowSubPaths(false);
      }
    } else if (isSalesRoute(location.pathname)) {
      if (props.path === routeConstant.sales.path) {
        setShowSubPaths(true);
      } else {
        setShowSubPaths(false);
      }
    } else {
      setShowSubPaths(false);
    }

    setActivePath(location.pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {subPaths ? (
        <>
          <div
            className={classNames(
              showSubPaths
                ? styles.activeSubNavItemContainer
                : styles.navItemContainer,
              "w-full pr-3 justify-between"
            )}
            onClick={() => {
              setShowSubPaths(!showSubPaths);
            }}
          >
            <div className={classNames("flex items-center")}>
              {Icon ? (
                <img src={Icon} alt="" className={classNames(styles.icon)} />
              ) : null}

              <label className={classNames(styles.heading)} role="button">
                {title}
              </label>
            </div>
            {subPaths ? <Icons.ChevDown /> : null}
          </div>
          {showSubPaths ? (
            <div
              className={classNames(
                styles.subPathsList,
                "w-full pr-3 justify-between"
              )}
            >
              {subPaths.map((subPath: SideBarItemPathType, index: number) => {
                return (
                  <Link
                    key={index}
                    to={subPath.path ? subPath.path : ""}
                    className={classNames(
                      activePath === subPath.path
                        ? styles.activeNavItemContainer
                        : styles.navItemContainer,
                      "w-full pr-3 justify-between"
                    )}
                  >
                    <div className={classNames("flex items-center")}>
                      {subPath.Icon ? (
                        <img
                          src={subPath.Icon}
                          alt=""
                          className={classNames(styles.icon)}
                        />
                      ) : null}

                      <label
                        className={classNames(styles.heading)}
                        role="button"
                      >
                        {subPath.title}
                      </label>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : null}
        </>
      ) : (
        <Link
          className={classNames(
            activePath === path
              ? styles.activeNavItemContainer
              : styles.navItemContainer,
            "w-full pr-3 justify-between"
          )}
          to={path ? path : ""}
        >
          <div className={classNames("flex items-center")}>
            {Icon ? (
              <img src={Icon} alt="" className={classNames(styles.icon)} />
            ) : null}

            <label className={classNames(styles.heading)} role="button">
              {title}
            </label>
          </div>
          {subPaths ? <Icons.ChevDown /> : null}
        </Link>
      )}
    </>
  );
};
export default NavItem;
