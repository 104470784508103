import { Images } from "assets";
import { useSelector } from "react-redux";

interface AvatarProps {
  profileURL: string;
}

const Avatar = ({ profileURL }: AvatarProps) => {
  const {
    auth: { user },
  } = useSelector((state: any) => state.root);
  return (
    <img
      src={
        profileURL
          ? profileURL
          : user?.profile_picture_full_path
          ? user?.profile_picture_full_path
          : Images.DefaultAvatar
      }
      alt="signup-avt"
    />
  );
};
export default Avatar;
