import classNames from "classnames";
import { useFormik } from "formik";
import CustomButton from "shared/components/common/customButton";
import CustomInput from "shared/components/common/customInput";
import CustomModal from "shared/components/common/customModal";
import CustomSelect from "shared/components/common/customSelect";
import CustomTextArea from "shared/components/common/customTextArea";
import ModalHeader from "shared/components/common/modalHeader";
import { AddElectricityVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import { BILL_TYPES, EXPENSES_TYPES } from "shared/utils/enum";
import {
  AddExpenseService,
  EditExpenseService,
} from "shared/services/expenseService";
import { toastMessage } from "shared/components/common/toast";
import moment from "moment";

interface InitialValuesProps {
  description: string;
  bill_type: string;
  amount: number;
  bill_type_id: number;
  date: string;
}

interface AddExpenseModalProps extends ModalProps {
  expenseItem?: any;
  expensesList?: any[];
  setExpensesList?: (value: any[]) => void;
  isEdit?: boolean;
}

const AddElectricityExpenseModal = ({
  isEdit,
  expenseItem,
  expensesList,
  show,
  handleClose,
  setExpensesList,
}: AddExpenseModalProps) => {
  const initialValues: InitialValuesProps = {
    date: expenseItem?.date
      ? moment(expenseItem?.date).format("YYYY-MM-DD")
      : "",
    description: expenseItem?.description || "",
    bill_type:
      expenseItem?.bill_type === BILL_TYPES.electricity
        ? "Electricity"
        : "Gas" || "",
    amount: expenseItem?.amount || 0,
    bill_type_id: 0,
  };

  const options = [
    {
      title: "Electricity",
      Icon: null,
      action: () => {
        setFieldValue("bill_type", "Electricity");
        setFieldValue("bill_type_id", BILL_TYPES.electricity);
      },
    },
    {
      title: "Gas",
      Icon: null,
      action: () => {
        setFieldValue("bill_type", "Gas");
        setFieldValue("bill_type_id", BILL_TYPES.gas);
      },
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddElectricityVS,
    onSubmit: (value, action) => {
      handleExpense();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
  } = formik;

  const handleExpense = () => {
    setSubmitting(true);
    let payload = {
      description: values.description,
      bill_type: values.bill_type_id,
      amount: values.amount,
      type: EXPENSES_TYPES.bill,
      date: values.date,
    };
    if (isEdit) {
      EditExpenseService(payload, expenseItem?._id)
        .then(({ data }) => {
          toastMessage("success", "Bill updated successfully");
          if (expenseItem) {
            const updatedList: any = expensesList?.map((item) => {
              if (item._id === expenseItem._id) {
                return data;
              }
              return item;
            });
            setExpensesList?.(updatedList);
            resetForm();
            handleClose();
          }
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      AddExpenseService(payload)
        .then(({ data }) => {
          toastMessage("success", "Bill added successfully");
          if (expensesList) {
            let tempExpensesList = [...expensesList];
            tempExpensesList.unshift(data);
            setExpensesList?.(tempExpensesList);
          }
          resetForm();
          handleClose();
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return show ? (
    <CustomModal>
      <div className={classNames(styles.contentContainer)}>
        <ModalHeader
          title="Add Electricity & Gas Expenses"
          handleClose={() => {
            resetForm();
            handleClose();
          }}
        />
        <div
          className={classNames(styles.modalBody, "w-full flex flex-col gap-3")}
        >
          <div className={classNames("flex flex-col gap-1 w-full")}>
            <CustomInput
              label="Date"
              placeholder="1/11/2021"
              required
              isDate
              type="date"
              value={values.date}
              error={touched.date && errors.date ? errors.date : ""}
              onChange={handleChange("date")}
            />
            <CustomSelect
              label="Bill Type"
              placeholder="Select Type"
              required
              value={values.bill_type}
              error={
                touched.bill_type && errors.bill_type ? errors.bill_type : ""
              }
              options={options}
            />
            <CustomInput
              label="Amount"
              placeholder="amount"
              required
              isNumber
              value={values.amount}
              error={touched.amount && errors.amount ? errors.amount : ""}
              formikKey="amount"
              setFieldValue={setFieldValue}
            />
            <CustomTextArea
              label="Description"
              placeholder="Write something"
              required
              customInputContainer={classNames(styles.customInputContainer)}
              value={values.description}
              error={
                touched.description && errors.description
                  ? errors.description
                  : ""
              }
              onChange={handleChange("description")}
            />
          </div>
          <CustomButton
            title={isEdit ? "Update" : "Add"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
    </CustomModal>
  ) : null;
};

export default AddElectricityExpenseModal;
