import classNames from "classnames";

import styles from "./style.module.scss";
import ModalWrapper from "../modalWrapper";

interface CustomModalProps {
  children: any;
}

const CustomModal = ({ children }: CustomModalProps) => {
  return (
    <ModalWrapper>
      <div className={classNames(styles.contentContainer)}>{children}</div>
    </ModalWrapper>
  );
};

export default CustomModal;
