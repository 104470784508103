import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoginVS } from "shared/utils/validation";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { routeConstant } from "shared/routes/routeConstant";
import { Icons, Images } from "assets";
import CustomInput from "shared/components/common/customInput";
import CustomButton from "shared/components/common/customButton";
import { setAuthReducer } from "shared/redux/reducers/authSlice";
import { LoginService } from "shared/services/authServices";
import { toastMessage } from "shared/components/common/toast";

const initialValues: LoginType = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: LoginVS,
    onSubmit: (value, action) => {
      handleLogin();
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = formik;

  const handleLogin = () => {
    setSubmitting(true);
    LoginService(values)
      .then(({ data, status }) => {
        if (status) {
          resetForm();
          dispatch(
            setAuthReducer({
              isLoggedIn: true,
              user: data?.user,
              token: data?.token,
            })
          );
          toastMessage("success", "Logged In Successfully");
        }
      })
      .catch((err: any) => {
        toastMessage("error", err?.response?.data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <main className={classNames(styles.mainContainer)}>
      <section
        className={classNames("w-full  flex flex-col items-center  relative")}
      >
        <Link
          to={routeConstant.login.path}
          className={classNames(styles.logoContainer)}
        >
          <img src={Images.Logo} alt="" />
        </Link>
        <div
          className={classNames(
            "flex items-center w-full justify-center m-auto"
          )}
        >
          <form
            className={classNames(styles.formContainer)}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={classNames("flex flex-col items-center gap-3")}>
              <h1 className={classNames(styles.title)}>Welcome Back!</h1>
              <p className={classNames(styles.subTitle)}>
                Please log in to access exclusive features and make the most of
                your stay.
              </p>
            </div>
            <div className={classNames("flex flex-col gap-1")}>
              <CustomInput
                label="Enter your email"
                placeholder="Enter your email"
                required
                Icon={Icons.Mail}
                type="email"
                value={values.email}
                error={touched.email && errors.email ? errors.email : ""}
                onChange={handleChange("email")}
              />
              <CustomInput
                label="Password"
                placeholder="Password"
                required
                isPassword
                Icon={Icons.Lock}
                type="password"
                value={values.password}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
                onChange={handleChange("password")}
              />
              {/* <label className={classNames(styles.forgotLabel)}>
                Forgot password?
              </label> */}
            </div>
            <div className={classNames("flex flex-col items-center gap-6")}>
              <CustomButton
                title="Log In"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Login;
