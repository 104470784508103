import { routeConstant } from "shared/routes/routeConstant";
import { SUPPORTED_FORMATS } from "./enum";
import { store } from "shared/redux/store";
import { resetAuthReducer } from "shared/redux/reducers/authSlice";
import { toastMessage } from "shared/components/common/toast";

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  } else {
    return {
      width: 0,
      height: 0,
    };
  }
}

function isImage(filetype: string): boolean {
  if (SUPPORTED_FORMATS.includes(filetype)) {
    return true;
  } else {
    return false;
  }
}

function findScreenTitle(pathname: string) {
  let arr = Object.values(routeConstant).filter(
    (itm) => itm?.path === pathname
  );
  if (arr?.length > 0) {
    return arr[0]?.title;
  } else {
    return "";
  }
}

function isNumberCheck(e: any) {
  e = e || window.event;
  return /[\d.]/.test(e.key);
}

const resetRedux = () => {
  const { auth }: any = store.getState().root;
  if (auth?.token) {
    store.dispatch(resetAuthReducer());
    toastMessage("error", "Session Expired");
    window.location.reload();
  }
};

function isExpensesRoute(pathname: string) {
  if (
    pathname === routeConstant.misc.path ||
    pathname === routeConstant.feed.path ||
    pathname === routeConstant.electicalBill.path ||
    pathname === routeConstant.vaccine.path ||
    pathname === routeConstant.medicine.path ||
    pathname === routeConstant.fuel.path ||
    pathname === routeConstant.salary.path
  ) {
    return true;
  }
  return false;
}

function isSalesRoute(pathname: string) {
  if (
    pathname === routeConstant.eggSales.path ||
    pathname === routeConstant.otherSales.path ||
    pathname === routeConstant.transferSales.path
  ) {
    return true;
  }
  return false;
}

export {
  getWindowDimensions,
  isImage,
  findScreenTitle,
  resetRedux,
  isNumberCheck,
  isExpensesRoute,
  isSalesRoute,
};
